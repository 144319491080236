
/* eslint-disable */
import * as Dto from '../dtos';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ProxyHttp } from '@wtw/platform/api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResult, BinaryTransferObject } from '@wtw/platform/api';
import { map } from 'rxjs/operators';
export { ApiResult };
import { UrlHelperService } from 'app/shared/helpers/url-helper.service';

@Injectable()
export class ScenarioProxy {
    constructor(private _phttp: ProxyHttp) { }


    scenarioData(scenarioId: number): Observable<ApiResult<Dto.ScenarioData>> {
        return this._phttp.get(false, `/scenario/${scenarioId}`, null || {}).pipe(map((ret: HttpResponse<Dto.ScenarioData>) => <ApiResult<Dto.ScenarioData>>({ data: ret.body, originalResponse: ret })));
    }
    savedScenarios(runId: number): Observable<ApiResult<Dto.ScenarioListDto[]>> {
        return this._phttp.get(false, `/scenario/savedScenarios/${runId}`, null || {}).pipe(map((ret: HttpResponse<Dto.ScenarioListDto[]>) => <ApiResult<Dto.ScenarioListDto[]>>({ data: ret.body, originalResponse: ret })));
    }
    validScenarios(runId: number): Observable<ApiResult<Dto.ScenarioListDto[]>> {
        return this._phttp.get(false, `/scenario/validScenarios/${runId}`, null || {}).pipe(map((ret: HttpResponse<Dto.ScenarioListDto[]>) => <ApiResult<Dto.ScenarioListDto[]>>({ data: ret.body, originalResponse: ret })));
    }
    createPrivateCompanyFinancial(runId: number, companyId: number): Observable<ApiResult<number>> {
        return this._phttp.post(false, `/scenario/privateCompany/createFinancial/${runId}/${companyId}`, null || {}).pipe(map((ret: HttpResponse<number>) => <ApiResult<number>>({ data: ret.body, originalResponse: ret })));
    }
    saveScenario(data: Dto.ScenarioData, existingScenarioId: number): Observable<ApiResult<Dto.SaveSettingOutput>> {
        return this._phttp.post(false, `/scenario/save/${existingScenarioId}`, data || {}).pipe(map((ret: HttpResponse<Dto.SaveSettingOutput>) => <ApiResult<Dto.SaveSettingOutput>>({ data: ret.body, originalResponse: ret })));
    }
    updateCurrency(runId: number, currencyCode: string): Observable<ApiResult<void>> {
        return this._phttp.post(false, `/scenario/updateCurrency/${runId}/${encodeURIComponent(currencyCode)}`, null || {}).pipe(map((ret: HttpResponse<void>) => <ApiResult<void>>({ data: ret.body, originalResponse: ret })));
    }
    updateRunFinancialData(data: Dto.RunFinancialData): Observable<ApiResult<Dto.RunViewModel>> {
        return this._phttp.post(false,  `/scenario/updateRunFinancialData`, data || {})
                .pipe(map((ret: HttpResponse<Dto.RunViewModel>) => <ApiResult<Dto.RunViewModel>>({  data: ret.body ?  ret.body : ret , originalResponse: ret })));
    }

}


