
/* eslint-disable */


export interface CompanyDataStatus {

	dataStatus: number;
	mandatoryCompletion?: number;
	supplementalCompletion?: number;
	statementCreated: boolean;
}





