import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'pdf-modal',
    templateUrl: '../../../../../node_modules/@acl/rtc-components/src/lib/pages/riskTolerance/pdfModal/pdfModal.component.html',
    styleUrls: ['../../../../../node_modules/@acl/rtc-components/src/lib/pages/riskTolerance/pdfModal/pdfModal.component.scss']
})
export class PdfModalComponent implements OnInit {
    public active = false;
    public data: any;
    public onClose: Subject<boolean>;
    public translationKey = 'RTC.RISKTOLERANCE.PDFMODAL';
    public translation: any = {

    };
    pdfSelection: number;
    public pdfForm: FormGroup;
    get selectedPdfValue(): number {
        return +this.pdfForm.get('selectedPdf').value;
    }

    constructor(public _bsModalRef: BsModalRef, private _fb: FormBuilder) {
        this.pdfForm = this._fb.group({
            selectedPdf: ['', Validators.required]
        });
    }

    ngOnInit() {
        this.onClose = new Subject();
    }

    public close() {
        this.hide(null);
    }

    public ok() {
        this.data = this.selectedPdfValue;
        this.hide(true);
    }

    public cancel() {
        this.hide(false);
    }

    public show() {
        this.active = true;
    }

    private hide(nextVal?: boolean) {
        this.active = false;
        this.onClose.next(nextVal);
        this._bsModalRef.hide();
    }
}
