
/* eslint-disable */

    
    export interface AccountingStandard  {
        
		name: string;
    }

    


    
