
/* eslint-disable */

    

    


    
    export enum FinancialSizeType{
    
    marketCapitalization,
    
    revenue,
    
    }
    
