import { Component, OnInit, Input, Output, AfterViewInit, EventEmitter } from '@angular/core';
import { RiskToleranceService } from 'app/services/riskTolerance.service';
import { TranslateService } from '@ngx-translate/core';
import { NumberPercentPipe } from '@wtw/toolkit';
import * as Highcharts from 'highcharts';
import { ReactiveComponent } from '@wtw/toolkit/src/utils/base.component';
import { ShortNumberFormatPipe } from 'app/pipes/ShortNumberFormatExtenderPipe';
import * as moment from 'moment';

@Component({
    selector: 'metric-details',
    templateUrl: '../../../../../../node_modules/@acl/rtc-components/src/lib/pages/riskTolerance/lossThresholds/metricDetails/metricDetails.commponent.html',
    styleUrls: ['../../../../../../node_modules/@acl/rtc-components/src/lib/pages/riskTolerance/lossThresholds/metricDetails/metricDetails.commponent.scss']
})

export class MetricDetailsComponent extends ReactiveComponent implements OnInit, AfterViewInit {
    @Input() metric: any;
    @Input() priority;
    @Input()
    set refreshGraph(value: number) {
        this.prepData();
        this.loading = true;
    }
    @Output() redirectMetric = new EventEmitter();
    selectedMetric: any;

    public translationKey = 'RTC.RISKTOLERANCE.LOSSTHRESHOLDS.METRICDETAILS';
    public peerGraphModalTransaltionKey = 'RTC.RISKTOLERANCE.LOSSTHRESHOLDS.METRICDETAILS.PEERGRAPHMODEL';
    public translation: any = {};
    public priorities;
    public model: any = {};
    public customThreshold = false;
    public viewAnalysis = false;
    public loading = true;
    public individualPeers = [];
    public customAnalysis: boolean;
    public isCreditFlag: boolean;
    public suggesteMetricByPeriod: any;

    private originalColours = ['#5D63D3', '#F24965', '#45cae6', '#ff8500',
        '#007771', '#30347d', '#8e0255', '#003bf7', '#b9e500', '#889e0a'];
    private curretCompColour = '#FFB81C';

    constructor(private riskToleranceService: RiskToleranceService, private translateService: TranslateService,
        private shortNumberPipe: ShortNumberFormatPipe, private percentPipe: NumberPercentPipe) {
        super();
    }

    ngOnInit() {
        this._subscriptions.push(this.riskToleranceService.prioritiesChanged.subscribe(_ => this.prepData()));
    }

    prepData() {
        this.priorities = this.riskToleranceService.riskTolerance.priorities.filter(c => !c.isDeleted);
        this.isCreditFlag = this.riskToleranceService.getCreditFlagForMetricPriority(this.priority.id, this.metric.id);

        this.model.metricName = this.metric.label;
        if (this.riskToleranceService.riskTolerance.customAnalysis) {
            this.customAnalysis = this.riskToleranceService.riskTolerance.customAnalysis
                .find(c => c.metricId === parseInt(this.metric.id, 10)
                    && c.priorityId === this.priority.id
                    && c.periodAnalysis.some(y => y.riskTolerance
                    && y.riskTolerance.riskTolerance != null)) !== undefined;
        } else {
            this.customAnalysis = false;
        }

        if (this.customAnalysis) {
            const sortedPeriod = this.riskToleranceService.getSortedTolerancePeriod(this.priority.id, this.metric.id);
            const lastPeriod = sortedPeriod != null && sortedPeriod.length > 0 ?
            sortedPeriod.find(item => item.acceptableDeviation !== undefined && item.acceptableDeviation !== null) : null;
            this.model.customAnalysis = {
                acceptableDeviation: this.percentPipe.transform(lastPeriod.acceptableDeviation, '1.0-2') + '%',
                metricName: this.metric.label,
                threshold: this.shortNumberPipe.transform(lastPeriod.riskTolerance),
                targetMetric: this.shortNumberPipe.transform(lastPeriod.targetMetric),
                year: new Date(lastPeriod.period).getFullYear()
            };
        }
        const values = this.riskToleranceService.getSuggesteMetricByPeriod(this.priority.id, this.metric.id)
            .sort((a, b) => new Date(b.period).valueOf() - new Date(a.period).valueOf());
        let showPercentChanged = 0;
        const acceptableDeviation = this.getAcceptableDeviation(values);
        values.forEach(d => {
            if (!isNaN(d.currentMetric)) {
                showPercentChanged++;
            }
            if (!this.model.threshold) {
                this.model.threshold = this.shortNumberPipe.transform(d.riskTolerance);
                this.model.targetMetric = this.shortNumberPipe.transform(d.targetMetric);
                this.model.currentMetric = this.shortNumberPipe.transform(d.currentMetric);
                this.model.currentMetric1 = d.currentMetric;
                this.model.projection = this.shortNumberPipe.transform(d.currentMetric);
                this.model.year = new Date(d.period).getFullYear();
                this.model.favorable = this.translateService.instant(this.translationKey + '.' + (d.peerPercentile > 0.5 ? 'FAVORABLE' : 'UNFAVORABLE'));
                this.model.acceptableDeviation =
                    (acceptableDeviation !== undefined && acceptableDeviation !== null) ?
                        this.percentPipe.transform(acceptableDeviation, '1.0-2') + '%' : '-';
                const groups = this.riskToleranceService.peerRefData.peerGroups
                    .concat(this.riskToleranceService.peerRefData.globalPeerGroups);
                this.model.peerGroup = groups.find(x => x.peerGroupId.toString()
                    === this.riskToleranceService.run.riskTolerance.selectedPeerGroup.toString());
                if (this.model.peerGroup) {
                    this.model.peerGroup = this.model.peerGroup.peerGroupName;
                }
                this.metric.riskTolerance = d.riskTolerance;
                if (d.riskTolerance === null) {
                    this.metric.description = this.translateService.instant(this.translationKey + '.' + 'DESCRIPTION4');
                }
            } else {
                if (this.model.threshold && this.model.percentChanged === undefined && this.model.historical === undefined) {
                    this.model.historical = this.shortNumberPipe.transform(d.currentMetric);
                    this.model.percentChanged = this.percentPipe
                        .transform((this.model.projection / this.model.historical - 1), '1.0-2') + '%';
                    if (parseFloat(this.model.percentChanged) >= 0) {
                        this.metric.direction = '/assets/ico_good_01.svg';
                    } else {
                        this.metric.direction = '/assets/ico_bad_01.svg';
                    }
                }
            }
        });
        if (showPercentChanged !== 3) {
            this.model.percentChanged = undefined;
        }
        const riskT = this.riskToleranceService.getMetricRiskTolerance(this.priority.id, this.metric.id);
        this.metric.min = riskT.length > 0 ? Math.min(...riskT) : 'N/A';
        this.metric.max = riskT.length > 0 ? Math.max(...riskT) : 'N/A';
    }

    loadMap() {
        const groups = this.riskToleranceService.peerRefData.peerGroups.concat(this.riskToleranceService.peerRefData.globalPeerGroups);
        this.model.peerGroup = groups.find(x => x.peerGroupId.toString() === this.riskToleranceService.run.riskTolerance.selectedPeerGroup.toString());
        const projectionDates = this.riskToleranceService.GetProjectionDates().map(c => moment(c).valueOf());
        let scatterData = [];
        this.individualPeers = [];
        if (this.riskToleranceService.run.riskTolerance.individualPeers &&
            this.riskToleranceService.run.riskTolerance.individualPeers.length) {
            const peerDictionary = this.riskToleranceService.run.riskTolerance.individualPeers.toDictionary('peerId');
            scatterData = this.riskToleranceService.getMetricPeriods(this.priority.id, this.metric.id)
                .map((c, i) => c.individualPeerValues.map((d, j) => {
                    if (i === 0) {
                        this.individualPeers.push({
                            name: peerDictionary[d.companyId].peerName,
                            index: j + 1 < this.originalColours.length ? j + 1 : this.originalColours.length - 1
                        });
                    }
                    return {
                        x: i,
                        y: d.value,
                        color: j < this.originalColours.length ?
                            this.originalColours[j] : this.originalColours[this.originalColours.length - 1],
                        name: peerDictionary[d.companyId].peerName,
                        metricName: this.metric.label,
                        metricNameText: this.translateService.instant(this.peerGraphModalTransaltionKey + '.METRICNAME'),
                        metricValueText: this.translateService.instant(this.peerGraphModalTransaltionKey + '.METRICVALUE'),
                        indevidualPeerText: this.translateService.instant(this.peerGraphModalTransaltionKey + '.INDIVIDUALPEER')
                    };
                }
                ));
        }
        scatterData.push(this.riskToleranceService.getMetricPeriods(this.priority.id, this.metric.id)
            .map((c, i) => ({
                x: i,
                y: c.currentMetric,
                color: this.curretCompColour,
                name: this.riskToleranceService.run.generalClientInfo.clientName,
                marker: {
                    symbol: 'triangle',
                    radius: 6
                },
                metricName: this.metric.label,
                metricNameText: this.translateService.instant(this.peerGraphModalTransaltionKey + '.METRICNAME'),
                metricValueText: this.translateService.instant(this.peerGraphModalTransaltionKey + '.METRICVALUE'),
                indevidualPeerText: this.translateService.instant(this.peerGraphModalTransaltionKey + '.INDIVIDUALPEER')
            })
            ));
        this.individualPeers = [
            {
                name: this.riskToleranceService.run.generalClientInfo.clientName,
                index: 0
            },
            ...this.individualPeers
        ];

        scatterData = scatterData.flatten();
        Highcharts.chart('metric-container', <any>{
            chart: {
                type: 'scatter',
                backgroundColor: '#ffffff',
                height: 280
            },
            title: {
                text: ''
            },
            credits: {
                enabled: false
            },
            legend: {
                enabled: false
            },
            xAxis: {
                categories: this.riskToleranceService.getMetricPeriods(this.priority.id, this.metric.id)
                    .map(c => {
                        const date = moment(c.period).valueOf();
                        const displayDate = this.riskToleranceService.financialStatement.finProjection.periodTypeId === 2 ?
                            `Q${moment(c.period).quarter()} ${moment(c.period).year()}` : `${moment(c.period).year()}`;
                        return projectionDates.find(d => d === date) ? `${displayDate} (Projection)` : displayDate;
                    }
                    ),
                title: {
                    text: this.translateService.instant(`${this.translationKey}.XAXIS`)
                }

            },
            yAxis: {
                title: {
                    text: this.translateService.instant(`${this.translationKey}.YAXIS`)
                },
            },
            // colors: this.colours,
            plotOptions: {
                bar: {
                    // grouping: false,
                    shadow: false,
                    borderWidth: 0,
                    pointPadding: .25,
                    groupPadding: 0,
                    pointWidth: 20
                },
                series: {
                    pointWidth: 20,
                    states: {
                        inactive: {
                            opacity: 1
                        }
                    },
                }
            },
            series:
            [
                {
                    name: this.translateService.instant(this.peerGraphModalTransaltionKey + '.PEERGROUPNAME') + ': ' + this.model.peerGroup.peerGroupName+'<br/>' +  this.translateService.instant(this.peerGraphModalTransaltionKey + '.METRICNAME') + ': ' + this.metric.label,
                    type: 'boxplot',
                    color: '#C111A0',
                    data: this.riskToleranceService.getMetricPeriods(this.priority.id, this.metric.id)
                        .map(c => ({
                            low: this.round(c.peerChart.minimum,2),
                            q1: this.round(c.peerChart.q1,2),
                            median: this.round(c.peerChart.median,2),
                            q3: this.round(c.peerChart.q3,2),
                            high: this.round(c.peerChart.maximum,2),
                            // name: new Date(c.period).getFullYear(),
                            color: '#C111A0'
                        })
                        ),
                    enableMouseTracking: true,

                },
                {
                    type: 'scatter',
                    name: 'Individual Peer',
                    data: scatterData,
                    jitter: {
                        x: 0.24 // Exact fit for box plot's groupPadding and pointPadding
                    },
                    marker: {
                        radius: 4
                    },
                    tooltip: {
                        headerFormat: null,
                        pointFormatter: function() {
                            return '<br/><br/>' + this.options.indevidualPeerText + ': ' + this.options.name +
                            '<br/><br/>' + this.options.metricNameText + ': ' + this.options.metricName +
                            '<br/><br/>' + this.options.metricValueText + ': ' + new ShortNumberFormatPipe().transform(this.options.y);
                        },
                        enabled: true
                    }
                }
            ]

        });
    }

    openAnalysis() {
        this.viewAnalysis = true;
    }

    closeAnalysis(metric: any) {
        this.metric = metric;
        this.viewAnalysis = false;
        this.model.historical = undefined;
        this.model.percentChanged = undefined;
        this.model.threshold = undefined;
        this.prepData();
        this.loading = true;
        this.redirectMetric.emit(metric);
    }

    loadingCompleted() {
        if (this.loading) {
            Promise.resolve(null).then(c => {
                this.loadMap();
            });
            this.loading = false;
            return false;
        }
        return true;
    }

    showDeviation() {
        return this.model && this.model.customAnalysis && this.model.customAnalysis.acceptableDeviation === 'null%' ? false : true;
    }

    getAcceptableDeviation(values: Array<any>) {
        const periods = values.map(x => x.period);
        const recentDate = periods.sort((a: any, b: any) => +new Date(b.period) - +new Date(a.period));
        const rawSuggestedObj = values.find(x => x.period === recentDate[0]);
        return rawSuggestedObj.acceptableDeviation;
    }

    ngAfterViewInit () {
        this.loading = true;
    }
     round(number: number, precision: number) {
         if (precision < 0) {
           let factor = Math.pow(10, precision);
           return Math.round(number * factor) / factor;
         }
         else
           return +(Math.round(Number(number + "e+" + precision)) +
             "e-" + precision);
       }
}
