
/* eslint-disable */
import { FinancialSizeType } from './FinancialSizeType';

export interface PeersData {

	peerGroups: PeerGroup[];
	industryGroups: IndustryGroup[];
	industries: Industry[];
	financialSizeTypes: FinancialSizeType[];
	geographyGroups: GeographyGroup[];
	geographies: Geography[];
	globalPeerGroups: PeerGroup[];
	maxCustomPeers: number;
}
export interface PeerGroup {

	peerGroupId: number;
	peerGroupName: string;
	numberOfCompanies: number;
	geographyId: string;
	geographyName: string;
}
export interface IndustryGroup {

	industryGroupId: number;
	industryGroupName: string;
}
export interface Industry {

	industryGroupId: number;
	industryId: number;
	industryName: string;
}
export interface GeographyGroup {

	geographyGroupId: number;
	geographyGroupName: string;
}
export interface Geography {

	geographyGroupId: number;
	geographyId: number;
	geographyName: string;
}





