

import { Component, OnInit } from '@angular/core';
import { RiskToleranceService } from 'app/services/riskTolerance.service';

@Component({
    selector: 'your-framework',
    templateUrl: '../../../../../node_modules/@acl/rtc-components/src/lib/pages/riskTolerance/yourFramework/yourFramework.component.html',
    styleUrls: ['../../../../../node_modules/@acl/rtc-components/src/lib/pages/riskTolerance/yourFramework/yourFramework.component.scss']
})

export class YourFrameworkComponent implements OnInit {
    public translationKey = 'RTC.RISKTOLERANCE.YOURFRAMEWORK';
    public translation: any = {

        HEADERSECTION: {
            TITLE: 'Apply Your Framework'
        }
    };

    public models = [3, 4];

    constructor(private riskToleranceService: RiskToleranceService) {
        // comment for lint to pass

    }

    ngOnInit() {
        this.models = this.riskToleranceService.run.riskTolerance.recommendedApplications;
        // comment for lint to pass
    }
}
