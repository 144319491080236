import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ClientService, RunService } from '@wtw/platform/services';
import { ReactiveComponent } from '@wtw/toolkit/src/utils/base.component';
import { RunViewModel } from 'app/api/generated/RunViewModel';
import { FinancialService } from 'app/services/financial.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FinancialDataModalComponent } from '../financial-data-modal/financial-data-modal.component';
import { ClientDetail } from 'app/api/generated/ClientDetail';
import { ClientData, RunModel } from '@wtw/platform/api/dtos';
import { Subscription } from 'rxjs';
import { ReferenceDataProxy } from 'app/api/dtos';

@Component({
    selector: 'financials-company-type-view',
    templateUrl: '../../../../../node_modules/@acl/rtc-components/src/lib/pages/financials/companyTypeView/companyTypeView.component.html',
    styleUrls: ['../../../../../node_modules/@acl/rtc-components/src/lib/pages/financials/companyTypeView//companyTypeView.component.scss']
})

export class CompanyTypeViewComponent extends ReactiveComponent implements OnInit, OnDestroy, AfterViewInit {

    public model: any;
    modalData: ClientDetail;
    existingRunModel: RunModel;
    currentClient: ClientData;

    public disableFinancialData = false;
    public translation: any = {
    };
    public translationKey = '';
    public showHide = false;
    public showCapIQBtn = false;
    private disableFinancialDataSubscription: Subscription;

    constructor(private financialService: FinancialService,
        private _runService: RunService,
        private modalService: BsModalService,
        private _refApi: ReferenceDataProxy,
        private _clients: ClientService) {
        super();
    }

    ngOnInit() {
        this.model = {};
        this.disableFinancialDataSubscription = this.financialService.isCalculationCompleted$.subscribe(value => {
            this.disableFinancialData = !value;
        });

        this._subscriptions = [
            this._runService.activeRun.subscribe(c => {
                this.existingRunModel = c;
                this.model = (<RunViewModel>c.data).generalClientInfo.dataStatus;
                this.translationKey = `RTC.FINANCIALS.COMPANYTYPEVIEW.TYPE${this.model.dataStatus}`;
                this.showCapIQBtn = this.model.dataStatus > 1;
                this.model.companyName = c.data.generalClientInfo.clientName;
                this.clientBasicFinancialDetails(c.data.generalClientInfo.clientId);
            }),
            this._clients.currentClientChanged.subscribe(c => this.currentClient = c)
        ];
    }

    ngAfterViewInit() {
        Promise.resolve(null).then(c => {
            Array.prototype.forEach.call(document.getElementsByTagName('currency'), (element) => {
                element.style.display = 'none';
            });
        });
    }

    ngOnDestroy() {
        this.disableFinancialDataSubscription.unsubscribe();
        Array.prototype.forEach.call(document.getElementsByTagName('currency'), (element) => {
            element.style.display = 'initial';
        });
    }

    hide() {
        this.financialService.HideCompanyTypeView();
    }

    showFinacialModal() {
        this.modalService.show(FinancialDataModalComponent,
            { class: 'modal-lg', backdrop: 'static' , initialState:
            { modalData: this.modalData, existingRun: this.existingRunModel, currentClient: this.currentClient } });
     }

    clientBasicFinancialDetails(clientId: number) {
              this._refApi.clientById(clientId).subscribe(x => {
                this.modalData = {
                    displayName: x.data.name || '-',
                    dunsId: x.data.dunsId || '-',
                    tickerCode: x.data.tickerCode || '-',
                    streetAddress: x.data.streetAddress || '-'
                };
         });
    }
}
