import { Injectable, Injector } from '@angular/core';
import { RunService } from '@wtw/platform/services';
import * as Dto from 'app/api/dtos'; 

@Injectable({
  providedIn: 'root'
})
export class RoutesService {
  public static currentRun: Dto.RunViewModel;

  private _runService: RunService;
  
  constructor(private inj: Injector) {

   }

   public init() {
    setTimeout(() => {
      this._runService = this.inj.get(RunService);
      
      this._runService.activeRun.subscribe(c => {
          RoutesService.currentRun= c.data;
      });
      
      this._runService.activeRunSubject$.resetPerformed.subscribe(c => {
        RoutesService.currentRun = null;
    });
  });
  }

  
}
