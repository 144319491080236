

import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FinancialService } from 'app/services/financial.service';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';


@Component({
    selector: 'save-financial-statements',
    templateUrl: '../../../../../../node_modules/@acl/rtc-components/src/lib/pages/financials/financialInputs/saveFinancials/saveFinancials.component.html',
    styleUrls: ['../../../../../../node_modules/@acl/rtc-components/src/lib/pages/financials/financialInputs/saveFinancials/saveFinancials.component.scss']
})

export class SaveFinancialsComponent implements OnInit {

    public saveFinanacialModel = {
        saveName: '',
        selectedStatementId: ''
    };

    public dataListHeader = {
        scenarioName: {
            icon: 'icon-combo-arrow',
            sortOrder: 0,
            sortFuntion(a, b) {
                return a.localeCompare(b);
            }
        },
        lastModified: {
            icon: 'icon-combo-arrow',
            sortOrder: 0,
            sortFuntion(a, b) {
                return moment(a).unix() - moment(b).unix();
            }
        },
        periodType: {
            icon: 'icon-combo-arrow',
            sortOrder: 0,
            sortFuntion(a, b) {
                return (a === b) ? 0 : a < b ? -1 : 1;
            }
        },
        reportingTemplateType: {
            icon: 'icon-combo-arrow',
            sortOrder: 0,
            sortFuntion(a, b) {
                return (a === b) ? 0 : a < b ? -1 : 1;
            }
        },
        projectionType: {
            icon: 'icon-combo-arrow',
            sortOrder: 0,
            sortFuntion(a, b) {
                return (a === b) ? 0 : a < b ? -1 : 1;
            }
        }
    };
    public translationKey = 'RTC.FINANCIALS.SAVEMODAL';

    public translation = {

    };
    public dataList: {
        finScenarioId: number;
        scenarioName: string;
        currentlySelected: boolean;
        lastModified: string;
        periodType: number;
        reportingTemplateType: number;
        projectionType: number;
        projectionValue: any;
    }[];
    public form: FormGroup;
    public active: boolean;
    public onClose: Subject<boolean>;
    public data: any;

    private customValidator = {
        saveName: [this.financialService.regexPatternValidator()]
    };

    constructor(private financialService: FinancialService, private fb: FormBuilder, public _bsModalRef: BsModalRef) { }

    ngOnInit() {
        this.onClose = new Subject();
        this.dataList = this.financialService.savedStatements;
        this.dataList.forEach(c => {
            (c as any).scenarioNameShorter = c.scenarioName.length > 20 ? c.scenarioName.substring(0, 20) + '...' : c.scenarioName;
        });
        this.form = Object.toFormGroup(this.saveFinanacialModel, this.fb, this.customValidator);
        this.form.controls.selectedStatementId.valueChanges.subscribe(value => {
            if (value) {
                const selected = this.dataList.find(x => x.finScenarioId === +value);
                if (selected) {
                    this.form.controls.saveName.setValue(selected.scenarioName);
                }
            }
        });
    }

    removeSelectedId() {
        if (this.form.controls.saveName.value) {
            this.form.controls.selectedStatementId.setValue('');
        }
    }
    sorHeader(item, key) {
        const order = item.sortOrder || -1;
        Object.keys(this.dataListHeader).forEach(c => {
            this.dataListHeader[c].sortOrder = 0;
            this.dataListHeader[c].icon = 'icon-combo-arrow';
        });
        item.sortOrder = order * -1;
        if (item.sortOrder === 1) {
            item.icon = 'icon-up_arrow';
        } else {
            item.icon = 'icon-down_arrow';
        }
        this.orderList(item, key);
    }

    orderList(item, key) {
        this.dataList = this.financialService.savedStatements
            .sort((a, b) => item.sortFuntion(a[key], b[key]) * item.sortOrder);
    }

    public close() {
        this.hide(null);
    }

    public ok() {
        this.data = this.form.getRawValue();

        if (!this.form.valid || this.data.saveName.trim().length === 0) {
            return;
        }
        this.hide(true);
    }

    public cancel() {
        this.hide(false);
    }

    public show() {
        this.active = true;
    }

    private hide(nextVal?: boolean) {
        this.active = false;
        this.data = this.form.getRawValue();

        if (this.data.saveName) {
            const alreadyExists = this.dataList.find(c => c.scenarioName === this.data.saveName);
            if (alreadyExists) {
                this.data.selectedStatementId = alreadyExists.finScenarioId;
            } else {
                this.data.selectedStatementId = 0;
            }
        }
        this.onClose.next(nextVal);
        this._bsModalRef.hide();
    }
}
