
/* eslint-disable */
import * as Dto from '../dtos'; 
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ProxyHttp  } from '@wtw/platform/api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResult,BinaryTransferObject} from '@wtw/platform/api';
import { map } from 'rxjs/operators'; 
export { ApiResult };
import { UrlHelperService } from 'app/shared/helpers/url-helper.service';

@Injectable()
export class RiskToleranceProxy {
    	constructor(private _phttp: ProxyHttp) { } 
    
    
    calculatePriorityResult(metrics: Dto.MetricVm[]): Observable<ApiResult<Dto.PriorityResultVm>> {
        return this._phttp.post(false,  `/riskTolerance/priorityResult`,metrics||{}).pipe(map((ret:HttpResponse<Dto.PriorityResultVm>) => <ApiResult<Dto.PriorityResultVm>>({  data: ret.body , originalResponse: ret })));
	}
    numberOfCompanies(customPeerGroup: Dto.CustomPeerGroup): Observable<ApiResult<number[]>> {
        return this._phttp.post(false,  `/riskTolerance/numberOfCompanies`,customPeerGroup||{}).pipe(map((ret:HttpResponse<number[]>) => <ApiResult<number[]>>({  data: ret.body , originalResponse: ret })));
	}
    dataStatus(companyId: number): Observable<ApiResult<number>> {
        return this._phttp.get(false,  `/riskTolerance/dataStatus/${companyId}`,null||{}).pipe(map((ret:HttpResponse<number>) => <ApiResult<number>>({  data: ret.body , originalResponse: ret })));
	}
    
}

	
