
/* eslint-disable */

    
    export interface ReportingTemplate  {
        
		reportingTemplateTypeId: number;
		reportingTemplateTypeName: string;
    }

    


    
