/* eslint-disable */

import { Action } from '@ngrx/store';

export const RESET_STATE = '[Application] reset state';

export class ResetState implements Action {
    readonly type = RESET_STATE;
}

import { createAction, props } from '@ngrx/store';

 //import { ClientDetails } from '../../api/generated/RunViewModel';
 //export const UpdateClientDetails = createAction('[ClientDetails] Update Client Details', props<{ clientdetails: ClientDetails }>());
 //export const UpdateClientName = createAction('[RunViewModel] Update Client Name', props<{ clientName: string }>());
