import { Injectable, Injector } from '@angular/core';
import { RiskToleranceService } from './riskTolerance.service';
import { HttpClient } from '@angular/common/http';
import { UrlHelperService } from '@wtw/platform/services/url-helper.service';

@Injectable()
export class DownloadService {

    private riskToleranceService: RiskToleranceService;
    private _nghttp: HttpClient;
    private platformUrl: any;
    private readonly BASE_URL = '/proxy/binary/pdf/root';

    constructor(private inj: Injector) {
    }

    init() {
        setTimeout(() => {
            this.platformUrl = UrlHelperService.getPlatformUrl();
            this.riskToleranceService = this.inj.get(RiskToleranceService);
            this._nghttp = this.inj.get(HttpClient);
        });
    }

    downloadPDF(parameter: string) {
        const url = `${this.platformUrl}${this.BASE_URL}?runId=${this.riskToleranceService.runInfo.runId}&parameter=${parameter}`;
        return this._nghttp.get<Blob>(url, {
          withCredentials: true,
          responseType: 'blob' as 'json'
        });
      }

}
