import { DecimalPipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'numberFormatter'
})
export class NumberFormatterPipe implements PipeTransform {
  private _decimal: DecimalPipe;
  constructor(@Inject(LOCALE_ID) private locale: string) { this._decimal = new DecimalPipe(this.locale); }

  transform(value: any, args?: any): any {
    if (!_.isNil(args)) {

      const getValue = (scale: number): string => {
        return this._decimal.transform(value, `1.${scale}-${scale}`);
      };

      args = args.split('.');

      if (args.length > 1) {
        const scale = args[0];
        const formatType = args[1];
        value = getValue(scale);

        if (formatType === 'percentage') {
          value = value + '%';
        }
      }
      return value.toLocaleString();
    }
    return value;
  }
}
