import { AbstractControl } from '@angular/forms';

export interface Industry  {
    id: number;
}

export class DuplicatedIndustryValidator {
    static duplicated(c: AbstractControl): { [key: string]: boolean } | null {
        if (c.value) {
            if (c.value.filter(i => isNaN(i) ).length > 0) {
                return { 'required': true };
            }
            const duplicated = DuplicatedIndustryValidator.getDuplicated(c.value);
            if (duplicated.length > 0) {
                return { 'duplicated': true };
            }
        }
        return null;
    }

    static getDuplicated(industries: number[]) {
        const duplicated: number[] = [];
        const sorted = industries.filter(i => !isNaN(i) && i != null).sort();
        for (let i = 0; i < sorted.length - 1; i++) {
            if (sorted[i + 1] === sorted[i]) {
                duplicated.push(sorted[i]);
            }
        }
        return duplicated;
    }
}
