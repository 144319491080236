
/* eslint-disable */
    export enum ScoreIndicatorEnum{
    
    higherIsBetter = 1,
    
    lowerIsBetter = 2,
    
    doNotScore = 3,
    
    }
    
