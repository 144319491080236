
/* eslint-disable */
import { PeriodTypeEnum } from './PeriodTypeEnum';
import { ReportingTemplateEnum } from './ReportingTemplateEnum';
import { ProjectionTypeEnum } from './ProjectionTypeEnum';

export interface ScenarioListDto {

    finScenarioId: number;
    scenarioName: string;
    currentlySelected: boolean;
    lastModified: Date;
    periodType: PeriodTypeEnum;
    reportingTemplateType: ReportingTemplateEnum;
    projectionType: ProjectionTypeEnum;
    projectionValue?: number;
}





