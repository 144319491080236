import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { RiskToleranceService } from 'app/services/riskTolerance.service';
import * as Highcharts from 'highcharts';
import { RunService } from '@wtw/platform/services';
import { ReactiveComponent } from '@wtw/toolkit/src/utils/base.component';
import { TranslateService } from '@ngx-translate/core';
import { ShortNumberFormatPipe } from 'app/pipes/ShortNumberFormatExtenderPipe';
@Component({
    selector: 'acceptable-Deviations',
    templateUrl: '../../../../../../node_modules/@acl/rtc-components/src/lib/pages/riskTolerance/lossThresholds/acceptableDeviations/acceptableDeviations.component.html',
    styleUrls: ['../../../../../../node_modules/@acl/rtc-components/src/lib/pages/riskTolerance/lossThresholds/acceptableDeviations/acceptableDeviations.component.scss']
})

export class AcceptableDeviationsComponent extends ReactiveComponent implements OnInit {

    @Output()
    prioritySelected = new EventEmitter<any>();
    public translationKey = 'RTC.RISKTOLERANCE.LOSSTHRESHOLDS.ACCEPTABLEDEVIATIONS';
    public graphTranslation = 'RTC.RISKTOLERANCE.GRAPHMODAL';
    public translation: any = {};
    public model: any;
    public priorities: any;
    public metrics: any;
    public riskTolerance: any;
    public riskToleranceData = [];
    public riskBearingCapacity: any;
    public riskBearingCapacityData = [];
    public priorityTolerance: any;
    public metricsTolerance: any;
    public highChartComponent: any = {};
    public actualMetricsArray: any = [];
    public xAxisCategory: any = [];
    private priorityToleranceColors = ['#C111A0', '#FFB81C', '#5D63D3', '#F24965', '#45cae6'];
    private priorityRangeColors = ['#ECB7E2', '#FFEAB9', '#CED0F2', '#FBC8D0', '#C7EFF7'];
    private metricColors = ['#6A0858', '#8C650F', '#333674', '#852837', '#266F7E'];

    constructor(private riskToleranceService: RiskToleranceService,
        private runService: RunService,
        private translateService: TranslateService) {
        super();
    }

    ngOnInit() {
        this._subscriptions.push(this.runService.activeRun.subscribe(c => {
            this.model = this.riskToleranceService.riskTolerance;
            this.priorities = this.riskToleranceService.riskTolerance.priorities.filter(d => !d.isDeleted);
            this.metrics = this.riskToleranceService.riskTolerance.metrics;
            this.highChartdata();
        }));
    }
    selectPriority(priority) {
        this.prioritySelected.emit(priority);
    }
    //#region updated highchart data preparation
    highChartdata() {
        this.riskTolerance = this.getLossThreshold(this.model.riskToleranceProperty);
        this.riskBearingCapacity = this.getLossThreshold(this.model.riskBearingCapacity);
        this.priorityTolerance = this.getPriorityTolerance();
        this.getDottedDashedLineData();
        this.getMetricTolerance();
        this.loadHighchart();
    }
    // function for Risk tolerance and risk bearing capacity
    getLossThreshold(priorityConfiguration: any) {
        const priorityId = priorityConfiguration.priorityId;
        const overwriteVlaue = priorityConfiguration.deviation;
        if (isNaN(priorityId) || priorityId < 1 || !this.priorities.find(x => x.id === priorityId).summaryValues) {
            return { name: null, value: 0.00 };
        } else {
            const requiredPriority = this.priorities.find(x => x.id === priorityId);
            return {
                name: requiredPriority.name,
                value: overwriteVlaue === null ? requiredPriority.summaryValues.suggested : overwriteVlaue
            };
        }
    }
    // functions for priorities and it's risk tolerances
    getPriorityTolerance() {
        if ( this.priorities !== null && this.priorities !== undefined && this.priorities.length > 0) {
            this.actualMetricsArray = [];
            this.xAxisCategory = [];
            for (let i = 0; i < this.priorities.length; i++) {
                this.priorities[i].priorityToleranceColor = this.priorityToleranceColors[i];
                this.priorities[i].priorityRangeColor = this.priorityRangeColors[i];
                this.priorities[i].metricColor = this.metricColors[i];
                this.priorities[i].priorityIndex = i;
                this.xAxisCategory.push(i);
                this.getActualMetricsArray(this.priorities[i]);
            }
            return {
                name: this.priorities.map(x => x.name),
                toleranceValue: this.priorities.map(x => {return {
                    name: x.name, x: x.priorityIndex, y: x.summaryValues.suggested, color: x.priorityToleranceColor,
                    marker: {lineColor: x.priorityToleranceColor},
                    priorityToleranceText: this.translateService.instant(this.graphTranslation + '.PRIORITYRISKLEGEND')
                }; }),
                rangevalue: this.priorities.map(x => { return {
                    x: x.priorityIndex, low : x.summaryValues.low, high: x.summaryValues.high, color: x.priorityRangeColor, name: x.name,
                    priorityRangeText: this.translateService.instant(this.graphTranslation + '.PRIORITYRANGE')
                }; }),
                priorityId: this.priorities.map(x => x.id)
            };
        } else {
            return {
                name: [],
                toleranceValue: [],
                rangevalue: [],
                priorityId: []
            };
        }
    }
    // function for metrics and metric values
    getActualMetricsArray(priority) {
        const metricsIds = priority.metrics;
        if (metricsIds !== null && metricsIds !== undefined && metricsIds.length > 0) {
            metricsIds.forEach(element => {
                this.actualMetricsArray.push ({
                    metricId: element,
                    label: this.metrics.find(x => x.id === element)['label'],
                    consequence: this.metrics.find(x => x.id === element)['consequence'],
                    priorityId: priority.id,
                    priorityIndex: priority.priorityIndex,
                    isCustomCheck: this.model.customAnalysis !== null && this.model.customAnalysis !== undefined ?
                    this.model.customAnalysis.find(x => x.metricId === element &&
                        x.priorityId === priority.id) !== undefined ? true : false : false,
                    toleranceThreshold: this.riskToleranceService.getLatestTolerancePeriodValue(priority.id, element),
                    currentMetric: this.riskToleranceService.getSortedTolerancePeriod(priority.id, element)[0]
                    !== null && this.riskToleranceService.getSortedTolerancePeriod(priority.id, element)[0]
                    !== undefined ? this.riskToleranceService.getSortedTolerancePeriod(priority.id, element)[0]['currentMetric'] :
                    null,
                    color: priority.metricColor,
                    priorityName: priority.name
                });
            });
        }
    }
    getMetricTolerance() {
        this.metricsTolerance = {
            customMetricThreshold : (this.actualMetricsArray.filter(x => x.isCustomCheck === true)).map(x => {return {
                x: x.priorityIndex, y: x.toleranceThreshold, color: x.color, name: x.label, type: 'Custom',
                priorityName: x.priorityName, currentMetric: x.currentMetric,
                metricText: this.translateService.instant(this.graphTranslation + '.METRIC'),
                metricValueText: this.translateService.instant(this.graphTranslation + '.METRICVALUE'),
                typeText: this.translateService.instant(this.graphTranslation + '.TYPE'),
                riskToleranceText: this.translateService.instant(this.graphTranslation + '.RISKTOLERANCELEGEND')

            }; }),
            defaultMetricThreshold : (this.actualMetricsArray.filter(x => x.isCustomCheck === false)).map(x => {return {
                x: x.priorityIndex, y: x.toleranceThreshold, color: x.color, name: x.label, type: 'Default',
                priorityName: x.priorityName, currentMetric: x.currentMetric,
                metricText: this.translateService.instant(this.graphTranslation + '.METRIC'),
                metricValueText: this.translateService.instant(this.graphTranslation + '.METRICVALUE'),
                typeText: this.translateService.instant(this.graphTranslation + '.TYPE'),
                riskToleranceText: this.translateService.instant(this.graphTranslation + '.RISKTOLERANCELEGEND')
            }; })
        };
    }
    getDottedDashedLineData() {
        if (this.riskTolerance.value !== null && this.riskTolerance.value !== undefined) {
            this.riskToleranceData = [];
            // To make the tooltip visible for dotted line when there is single priority
            if (this.priorities.length === 1) {
                this.riskToleranceData = [this.riskTolerance.value, this.riskTolerance.value];
            } else {
                for (let i = 0; i < this.priorities.length; i++) {
                    this.riskToleranceData.push(this.riskTolerance.value);
                }
            }
        }

        if (this.riskBearingCapacity.value !== null && this.riskBearingCapacity.value !== undefined) {
            this.riskBearingCapacityData = [];
            // To make the tooltip visible for dashed line when there is single priority
            if (this.priorities.length === 1) {
                this.riskBearingCapacityData = [this.riskBearingCapacity.value, this.riskBearingCapacity.value];
            } else {
                for (let i = 0; i < this.priorities.length; i++) {
                    this.riskBearingCapacityData.push(this.riskBearingCapacity.value);
                }
            }
        }
    }
    // Highchart function
    loadHighchart() {
        const dashedPlotLine = 'dashedPlotLine';
        const dottedPlotLine = 'dottedPlotLine';
        const dottedPlotLineOptions = {
            color: 'rgb(0,0,0)',
            id: dottedPlotLine,
            dashStyle: 'ShortDot',
            width: 3,
            value: this.riskTolerance.value
        };
        const dashedPlotLineOptions = {
            color: 'rgb(0,0,0)',
            id: dashedPlotLine,
            dashStyle: 'ShortDash',
            width: 3,
            value: this.riskBearingCapacity.value
        };
        (<any>Highcharts).Renderer.prototype.symbols.line = function (x, y, width, height) {
            return ['M', x - 10, y + width / 2, 'L', x + height + 10, y + width / 2];
        };
        this.highChartComponent = Highcharts.chart('container', <any> {
            chart: {
                height: 320,
                inverted: true
            },
            title: {
                text: ''
            },
            credits: {
                enabled: false
            },
            legend: {
                align: 'left',
                enabled: false,
                useHtml: true,
                lineHeight: 30
            },
            tooltip: {
                enabled: true
            },
            xAxis: {
                gridLineWidth: 1,
                labels: {
                    enabled: false
                },
                title: {
                    text: ''
                },
                tickPositions: this.xAxisCategory
            },
            yAxis: {
                minTickInterval: (Math.max.apply(null, this.priorities.map(x => x.max))) / 5,
                gridLineWidth: 0,
                title: {
                    text: this.translateService.instant(this.graphTranslation + '.TOLERANCETHRESHOLD')
                },
                plotLines:
                [
                    dottedPlotLineOptions, dashedPlotLineOptions
                ],
                labels: {
                    formatter: function() {
                      return new ShortNumberFormatPipe().transform(this.value);
                    }
                }
            },
            plotOptions: {
                bar: {
                    grouping: false,
                    shadow: false,
                    borderWidth: 0,
                    pointPadding: .25,
                    groupPadding: 0,
                    pointWidth: 20
                },
                series: {
                    pointWidth: 20,
                    states: {
                        inactive: {
                            opacity: 1
                        }
                    },
                    stickyTracking: false
                }
            },
            series:
            [
                {
                    name: this.translateService.instant(this.graphTranslation + '.RISKTOLERANCELEGEND'),
                    text: this.translateService.instant(this.graphTranslation + '.SELECTED'),
                    data: this.riskToleranceData,
                    dashStyle: 'ShortDot',
                    color: 'transparent',
                    marker: {
                        enabled: false
                    },
                    tooltip: {
                        headerFormat: null,
                        pointFormatter: function() {
                          return this.series.userOptions.name +
                          '<br/><br/>' + this.series.userOptions.text + ': ' +
                          new ShortNumberFormatPipe().transform(this.options.y);
                        }
                    },
                    events: { legendItemClick: function(event) { return false; } }
                },
                {
                    name: this.translateService.instant(this.graphTranslation + '.RISKBEARINGLEGEND'),
                    text: this.translateService.instant(this.graphTranslation + '.SELECTED'),
                    data: this.riskBearingCapacityData,
                    dashStyle: 'shortdash',
                    color: 'transparent',
                    marker: {
                        enabled: false
                    },
                    tooltip: {
                        headerFormat: null,
                        pointFormatter: function() {
                          return this.series.userOptions.name +
                          '<br/><br/>' + this.series.userOptions.text + ': ' +
                          new ShortNumberFormatPipe().transform(this.options.y);
                        }
                    },
                    events: { legendItemClick: function(event) { return false; } }
                },
                {
                    type: 'columnrange',
                    colorByPoint: true,
                    legendType: 'point',
                    showInLegend: false,
                    data: this.priorityTolerance.rangevalue,
                    lineWidth: 0,
                    marker: {
                        enabled: false
                    },
                    tooltip: {
                        headerFormat: null,
                        pointFormatter: function() {
                            return this.options.name + '<br/><br/>' +
                            this.options.priorityRangeText + ': ' +
                            new ShortNumberFormatPipe().transform(this.options.low) + ' - ' +
                            new ShortNumberFormatPipe().transform(this.options.high);
                        }
                    }
                },
                {
                    name: this.translateService.instant(this.graphTranslation + '.PRIORITYRISKLEGEND'),
                    type: 'scatter',
                    legend: {
                        enabled: true
                    },
                    marker: {
                        symbol: 'line',
                        lineWidth: 15,
                        radius: 20,
                        lineColor: 'black'
                    },
                    tooltip: {
                        headerFormat: null,
                        pointFormatter: function() {
                          return this.options.name +
                          '<br/><br/>' + this.options.priorityToleranceText + ': ' +
                          new ShortNumberFormatPipe().transform(this.options.y);
                        }
                    },
                    data: this.priorityTolerance.toleranceValue,
                },
                {
                    name: this.translateService.instant(this.graphTranslation + '.DEFAULTLEGEND'),
                    color: 'black',
                    type: 'scatter',
                    zoomType: 'xy',
                    data: this.metricsTolerance.defaultMetricThreshold,
                    marker: {
                        symbol: 'circle',
                        radius: 10
                    },
                    tooltip: {
                        headerFormat: null,
                        pointFormatter: function() {
                            return this.options.priorityName +
                            '<br/><br/>' + this.options.metricText + ': ' + this.options.name +
                            '<br/><br/>' + this.options.typeText + ': ' + this.options.type +
                            '<br/><br/>' + this.options.metricValueText + ': ' + this.options.currentMetric +
                            '<br/><br/>' + this.options.riskToleranceText + ': ' + new ShortNumberFormatPipe().transform(this.options.y);
                        }
                    },
                    cursor: 'pointer',
                    events:
                    {
                        click: (event) => {
                            this.selectPriority(this.priorities[this.metricColors.indexOf(event.point.color)]);
                            return false;
                        }
                    }
                },
                {
                    name: this.translateService.instant(this.graphTranslation + '.CUSTOMLEGEND'),
                    color: 'black',
                    type: 'scatter',
                    zoomType: 'xy',
                    data: this.metricsTolerance.customMetricThreshold,
                    marker: {
                        symbol: 'diamond',
                        radius: 10
                    },
                    tooltip: {
                        headerFormat: null,
                        pointFormatter: function() {
                            return this.options.priorityName +
                            '<br/><br/>' + this.options.metricText + ': ' + this.options.name +
                            '<br/><br/>' + this.options.typeText + ': ' + this.options.type +
                            '<br/><br/>' + this.options.metricValueText + ': ' + this.options.currentMetric +
                            '<br/><br/>' + this.options.riskToleranceText + ': ' + new ShortNumberFormatPipe().transform(this.options.y);
                        }
                    },
                    cursor: 'pointer',
                    events:
                    {
                        click: (event) => {
                            this.selectPriority(this.priorities[this.metricColors.indexOf(event.point.color)]);
                            return false;
                        }
                    }
                }
            ]
        });
    }
    //#endregion
}
