

import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
    selector: 'percentiles-modal',
    templateUrl: '../../../../../../node_modules/@acl/rtc-components/src/lib/pages/riskTolerance/definePriorities/percentiles/percentiles.component.html',
    styleUrls: ['../../../../../../node_modules/@acl/rtc-components/src/lib/pages/riskTolerance/definePriorities/percentiles/percentiles.component.scss']
})

export class PercentilesComponent implements OnInit {


    @Input() metric: any;
    public translationKey = 'RTC.RISKTOLERANCE.DEFINEPRIORITIES.PERCENTILES';

    public translation: any = {
    };
    public data: any;
    public onClose: Subject<boolean> = new Subject();
    public customValidator = {
        lowDeviation: [Validators.required, Validators.min(0), Validators.max(100)],
        highDeviation: [Validators.required, Validators.min(0), Validators.max(100)],
        lowOutlier: [Validators.required, Validators.min(0), Validators.max(100)],
        highOutlier: [Validators.required, Validators.min(0), Validators.max(100)]
    };


    form: any;

    constructor(private fb: FormBuilder, private _bsModalRef: BsModalRef) { }

    ngOnInit(): void {
        // empty for lint
    }
    public close() {
        this.hide(null);
    }

    public ok() {
        if (!this.form.valid) {
            this.form.controls.lowDeviation.markAsTouched();
            this.form.controls.highDeviation.markAsTouched();
            this.form.controls.lowOutlier.markAsTouched();
            this.form.controls.highOutlier.markAsTouched();
            return;
        }
        const data = this.form.getRawValue();
        this.data.lowDeviation = data.lowDeviation / 100;
        this.data.highDeviation = data.highDeviation / 100;
        this.data.lowOutlier = data.lowOutlier / 100;
        this.data.highOutlier = data.highOutlier / 100;
        this.hide(true);
    }

    public cancel() {
        this.hide(false);
    }

    public show() {
        this.form = Object.toFormGroup({
            lowDeviation: this.data.lowDeviation * 100,
            highDeviation: this.data.highDeviation * 100,
            lowOutlier: this.data.lowOutlier * 100,
            highOutlier: this.data.highOutlier * 100
        }, this.fb, this.customValidator);
    }

    private hide(nextVal?: boolean) {
        this._bsModalRef.hide();
    }
}
