import { Component, Input, Output, EventEmitter, ViewChildren, QueryList} from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Observable} from 'rxjs';
import {AutocompleteComponent} from '@wtw/toolkit/src/directives/autocomplete.directive';
import { debounceTime } from 'rxjs/operators';
import { ReferenceDataProxy } from 'app/api/dtos';

@Component({
    selector: 'click-auto-complete',
    templateUrl: '../../../../../../node_modules/@acl/rtc-components/src/lib/pages/riskTolerance/lossThresholds/peerGroupModal/clickAutoComplete.component.html',
    styleUrls: ['../../../../../../node_modules/@acl/rtc-components/src/lib/pages/riskTolerance/lossThresholds/peerGroupModal/clickAutoComplete.component.scss']
})
export class ClickAutoCompleteComponent {
    @Input() search: (term: string) => Observable<any>;
    @Output() itemSelected: EventEmitter<any> = new EventEmitter<any>();
    @ViewChildren(AutocompleteComponent) complete: QueryList<AutocompleteComponent>;
    public translationKey = 'RTC.RISKTOLERANCE.INDIVIDUAL-PEER';

    @Input() parent: FormGroup;

    get individualPeerFormArray(): FormArray {
        return this.parent.get('selectedIndividualPeers') as FormArray;
    }
    get individualPeersControls(): AbstractControl[] {
        return this.individualPeerFormArray.controls;
    }

    constructor(private _fb: FormBuilder, private _refApi: ReferenceDataProxy) {
    }

    activate(item: any, index: number) {
        this._refApi.companyDataStatus(item.id).uiSignal('Loading').subscribe(d => {
            const patched = {
                peerId: item.id,
                peerName: item.name,
                displaySearch: false,
                dataStatus: d.data
            };
            this.individualPeerFormArray.at(index).patchValue(patched);
        });
    }
    clicked(item: any, index: number) {
        const patched = {
            peerId: item.peerId,
            peerName: item.peerName,
            displaySearch: true,
            dataStatus: item.dataStatus
        };
        this.individualPeerFormArray.at(index).patchValue(patched);
        this.complete.toArray()[index].jacker.focus();
        this.complete.toArray()[index].jacker.blurred.pipe(debounceTime(300)).subscribe(() => {
            if (this.individualPeerFormArray.at(index).value.displaySearch) {
                this.individualPeerFormArray.at(index).patchValue({
                    peerId: this.individualPeerFormArray.at(index).value.peerId,
                    peerName: this.individualPeerFormArray.at(index).value.peerName,
                    dataStatus: this.individualPeerFormArray.at(index).value.dataStatus,
                    displaySearch: false
                });
            }
         });
    }

    addPeer(item) {
        this.individualPeerFormArray.push(this.createPeer(item));
    }

    removePeer(index: number) {
        this.individualPeerFormArray.removeAt(index);
    }

    private createPeer(item): FormGroup {
        const group = this._fb.group({
            'peerId': item.peerId || 0,
            'peerName': [item.peerName || ''],
            'displaySearch': [true],
            'dataStatus': [0]
        });
        return group;
    }
}
