
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { RiskToleranceService } from 'app/services/riskTolerance.service';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ShortNumberFormatPipe } from 'app/pipes/ShortNumberFormatExtenderPipe';

@Component({
    selector: 'summary-priority-modal',
    templateUrl: '../../../../../node_modules/@acl/rtc-components/src/lib/pages/riskTolerance/summaryPriorityModal/summaryPriorityModal.component.html',
    styleUrls: ['../../../../../node_modules/@acl/rtc-components/src/lib/pages/riskTolerance/summaryPriorityModal/summaryPriorityModal.component.scss']
})

export class SummaryPriorityModalComponent implements OnInit, AfterViewInit {
    public translationKey = 'RTC.RISKTOLERANCE.SUMMARYPRIORITYMODAL';
    public translation: any = {
    };
    public active = false;
    public form: any;
    public data: any;
    public onClose: Subject<boolean>;

    public priorities: any;
    public metrics: any;
    public consequences: any;
    public priorityKeys: number[];
    public suggestedThreshold: { value: string; min: string; max: string; };

    private model: {
        priorityId: number,
        definition: string,
        deviation: number
    };
    constructor(private fb: FormBuilder, private riskToleranceService: RiskToleranceService, public _bsModalRef: BsModalRef
        , private shortNumberFormat: ShortNumberFormatPipe) { }

    ngOnInit() {
        this.onClose = new Subject();
    }

    ngAfterViewInit() {
        Promise.resolve(null).then(cc => {
            if (this.data === 1) {
                this.model = JSON.parse(JSON.stringify(this.riskToleranceService.riskTolerance.riskToleranceProperty));
            } else if (this.data === 2) {
                this.model = JSON.parse(JSON.stringify(this.riskToleranceService.riskTolerance.riskBearingCapacity));
            } else {
                return;
            }
            this.form = Object.toFormGroup(this.model, this.fb);
            this.form.controls.priorityId.valueChanges.subscribe(value => {
                this.model.priorityId = value;
                this.consequences = this.priorities[this.model.priorityId].metrics.map(c => c.consequence);
                this.suggestedThreshold = this.getLossThreshold(this.form.controls.priorityId.value);
            });
            this.metrics = this.riskToleranceService.riskTolerance.metrics.toDictionary('id');
            this.priorities = JSON.parse(JSON.stringify(
                this.riskToleranceService.riskTolerance.priorities.filter(c => !c.isDeleted))).toDictionary('id');
            this.priorityKeys = Object.keys(this.priorities).map(c => +c);
            if (this.model.priorityId) {
                this.consequences = this.priorities[this.model.priorityId].metrics.map(c => c.consequence);
            }
            this.active = true;
            this.suggestedThreshold = this.getLossThreshold(this.form.controls.priorityId.value);
        });
    }

    public close() {
        this.hide(null);
    }

    public ok() {
        if (!this.form.valid) {
            return;
        }
        this.hide(true);
    }

    public cancel() {
        this.hide(false);
    }

    public show() {
        this.active = true;
    }

    sanitizeInput() {
        this.data.definition = this.riskToleranceService.sanitizeInput(this.data.definition);
        this.form.controls.definition.setValue(this.data.definition);
    }


    private hide(nextVal?: boolean) {
        this.active = false;
        this.data = this.form.getRawValue();
        this.sanitizeInput();
        this.onClose.next(nextVal);
        this._bsModalRef.hide();
    }

    private getLossThreshold(priority) {
        if (isNaN(priority) || priority < 1) {
            return {
                value: null,
                min: null,
                max: null
            };
        }
        return {
            value: this.shortNumberFormat.transform(this.priorities[priority].summaryValues.suggested),
            min: this.shortNumberFormat.transform(this.priorities[priority].summaryValues.low),
            max: this.shortNumberFormat.transform(this.priorities[priority].summaryValues.high)
        };
    }
}
