
/* eslint-disable */
import {FinancialSizeType} from './FinancialSizeType';
    
    export interface CustomPeerGroup  {
        
		industryGroupId: number;
		industryIds: number[];
		geographyGroupId: number;
		geographyIds: number[];
		financialSize: FinancialSizeType;
		rangeMinimum: number;
		rangeMaximum: number;
		currencyCode: string;
		peers: number[];
    }

    


    
