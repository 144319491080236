
/* eslint-disable */
import {CompanyDataStatus} from './CompanyDataStatus';
import {AccountingStandard} from './AccountingStandard';
import {CompanyAddress} from './CompanyAddress';
    
    export interface CompanyInfo  {
        
		employees?: number;
		revenue?: number;
		salesChange?: number;
		sector: string;
		industry: string;
		clientName: string;
		primaryExchange: string;
		clientId: number;
		dataStatus: CompanyDataStatus;
		accountingStandard: AccountingStandard;
		companyAddress: CompanyAddress;
		defaultCurrencyCode: string;
    }

    


    
