
/* eslint-disable */
import { ScoreIndicatorEnum } from './ScoreIndicatorEnum';

export interface DefinedPrioritiesRefData {

    scoreIndicators: ScoreIndicatorEnum[];
    deviationRef: DeviationRef;
    templateDataItemIds: number[];
    defaultSeverityScore: number;
}
export interface DeviationRef {

    lowOutlier?: number;
    highOutlier?: number;
    lowDeviation?: number;
    hIghDeviation?: number;
}





