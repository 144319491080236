export interface RunFinancialData {
    clientId: number;
    clientName: string;
    exchangeCode: string;
    revenue: number;
    numberofEmployees: number;
    originalClientId: number;
    originalClientName: string;
    runId: number;
}
