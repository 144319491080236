
/* eslint-disable */
import * as Dto from '../dtos'; 
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ProxyHttp  } from '@wtw/platform/api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResult,BinaryTransferObject} from '@wtw/platform/api';
import { map } from 'rxjs/operators'; 
export { ApiResult };
import { UrlHelperService } from 'app/shared/helpers/url-helper.service';

@Injectable()
export class ReferenceDataProxy {
    	constructor(private _phttp: ProxyHttp) { } 
    
    
    financialSettings(companyId: number, dataStatus: number): Observable<ApiResult<Dto.FinancialSettingData>> {
        return this._phttp.get(false,  `/reference/financialSettings/${companyId}/${dataStatus}`,null||{}).pipe(map((ret:HttpResponse<Dto.FinancialSettingData>) => <ApiResult<Dto.FinancialSettingData>>({  data: ret.body , originalResponse: ret })));
	}
    definedPriorityReferenceData(selectedScenarioId: number): Observable<ApiResult<Dto.DefinedPrioritiesRefData>> {
        return this._phttp.get(false,  `/reference/definedPriorities/${selectedScenarioId}`,null||{}).pipe(map((ret:HttpResponse<Dto.DefinedPrioritiesRefData>) => <ApiResult<Dto.DefinedPrioritiesRefData>>({  data: ret.body , originalResponse: ret })));
	}
    peersReferenceData(): Observable<ApiResult<Dto.PeersData>> {
        return this._phttp.get(false,  `/reference/peers`,null||{}).pipe(map((ret:HttpResponse<Dto.PeersData>) => <ApiResult<Dto.PeersData>>({  data: ret.body , originalResponse: ret })));
	}
    basicClientData(term: string): Observable<ApiResult<Dto.ClientPeer[]>> {
        return this._phttp.post(false,  `/reference/clients/${encodeURIComponent(term)}`,null||{}).pipe(map((ret:HttpResponse<Dto.ClientPeer[]>) => <ApiResult<Dto.ClientPeer[]>>({  data: ret.body , originalResponse: ret })));
	}
    companyDataStatus(companyId: number): Observable<ApiResult<number>> {
        return this._phttp.post(false,  `/reference/client/dataStatus/${companyId}`,null||{}).pipe(map((ret:HttpResponse<number>) => <ApiResult<number>>({  data: ret.body , originalResponse: ret })));
	}

    clientById(companyId: number): Observable<ApiResult<Dto.ClientInfo>> {
        return this._phttp.get(false, `/reference/clients/${companyId}`, null || {}).pipe(map((ret: HttpResponse<Dto.ClientInfo>) => <ApiResult<Dto.ClientInfo>>({ data: ret.body, originalResponse: ret })));
    }
    clientSearchFinancialData(term: string): Observable<ApiResult<Dto.ClientInfo[]>> {
    return this._phttp.get(false, `/reference/clientSearchFinancialData?term=${encodeURIComponent(term)}`, null || {}).pipe(map((ret: HttpResponse<Dto.ClientInfo[]>) => <ApiResult<Dto.ClientInfo[]>>({ data: ret.body, originalResponse: ret })));
    }
    
}

	
