
/* eslint-disable */


export interface SaveSettingOutput {

    scenarioId: number;
    triggerName: string;
}





