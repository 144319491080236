
/* eslint-disable */

    
    export interface ShareEarning  {
        
		date: string;
		actual: number;
		analystRange: number;
		consesus: number;
    }

    


    
