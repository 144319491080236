
/* eslint-disable */

    
    export interface CloneRunOutput  {
        
		selectedScenarioId: number;
    }

    


    
