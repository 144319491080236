

import { Component, OnInit } from '@angular/core';
import { FinancialService } from 'app/services/financial.service';
import { ReactiveComponent } from '@wtw/toolkit/src/utils/base.component';
import { RunService } from '@wtw/platform/services';

@Component({
    selector: 'financials',
    templateUrl: '../../../../node_modules/@acl/rtc-components/src/lib/pages/financials/financials.component.html'
})

export class FinancialsComponent extends ReactiveComponent implements OnInit {
    public model: any = {
        show: true
    };

    public financialService: FinancialService;

    constructor(financialService: FinancialService, private runService: RunService) {
        super();
        this.financialService = financialService;
    }

    ngOnInit() {
        this._subscriptions.push(this.runService.activeRun.subscribe(c => {
            this.financialService.reloadRun(c);
        })
        );
        this.financialService.showCompanyType = true;
    }
}
