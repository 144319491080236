import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ClientPeer, IndividualPeer, ReferenceDataProxy } from 'app/api/dtos';
import { RiskToleranceService } from 'app/services/riskTolerance.service';
import { CustomPeerGroup } from 'app/api/generated/CustomPeerGroup';

@Component({
    selector: 'peer-group-modal',
    templateUrl: '../../../../../../node_modules/@acl/rtc-components/src/lib/pages/riskTolerance/lossThresholds/peerGroupModal/peerGroupModal.component.html',
    styleUrls: ['../../../../../../node_modules/@acl/rtc-components/src/lib/pages/riskTolerance/lossThresholds/peerGroupModal/peerGroupModal.component.scss']
})
export class PeerGroupModalComponent implements OnInit {

    public translation: any = {
    };

    public translationKey = 'RTC.RISKTOLERANCE.PEERGROUP';

    individualPeers: ClientPeer[];
    customPeers: CustomPeerGroup;

    @Output() peerGroupClose = new EventEmitter();
    public peerGroupForm: FormGroup;
    customPeerOpen: boolean;
    showErrorMessage = false;
    get isValid() {
        const selectedPeer = this.peerGroupForm.get('selectedPeer').value;
        return selectedPeer !== 1000 || this.customPeers;
    }

    get peerGroupKeys() {
        return ['United States', 'Europe', 'Asia / Pacific'];
    }

    get globalKeys() {
        return ['Global', 'Canada', 'Latin America and Caribbean'];
    }
    get peerGroups() {
        return this.rtService.peerRefData.peerGroups.sort((a, b) => {
            if (a.peerGroupName < b.peerGroupName) { return -1; }
            if (a.peerGroupName > b.peerGroupName) { return 1; }
            return 0;
        }).groupBy('geographyName');
    }

    get globalPeerGroups() {
        return this.rtService.peerRefData.globalPeerGroups.sort((a, b) => {
            if (a.peerGroupName < b.peerGroupName) { return -1; }
            if (a.peerGroupName > b.peerGroupName) { return 1; }
            return 0;
        }).groupBy('geographyName');
    }

    individualPeer: boolean;

    constructor(private rtService: RiskToleranceService, private _fb: FormBuilder, private _refApi: ReferenceDataProxy) {
    }

    ngOnInit(): void {
        this.peerGroupForm = this._fb.group({
            selectedPeer: [this.rtService.run.riskTolerance.selectedPeerGroup],
            selectedIndividualPeers: this._fb.array(this.rtService.run.riskTolerance.individualPeers)
        });
        this.customPeers = JSON.parse(JSON.stringify(this.rtService.run.riskTolerance.customPeerGroup));
    }

    close() {
        this.peerGroupClose.emit(null);
    }

    cancel() {
        this.peerGroupClose.emit(null);
    }

    ok() {
        if (!this.isValid) {
            this.showErrorMessage = true;
            return;
        }
        const selectedPeer = this.peerGroupForm.get('selectedPeer').value;
        const individualPeers = this.peerGroupForm.get('selectedIndividualPeers').value as IndividualPeer[];
        const filteredPeers = individualPeers.filter(x => x.peerId > 0);
        this.rtService.updatePeer(selectedPeer, filteredPeers, this.customPeers);
        this.rtService.calltrigger('calc_peer_analysis').subscribe(c => {
            this.peerGroupClose.emit(null);
        });
    }

    openCustomPeer() {
        this.customPeerOpen = true;
    }

    closeCustomPeer(customPeers) {
        this.customPeerOpen = false;

        if (customPeers !== null) {
            this.customPeers = customPeers;
        }
    }

    search: any = (term: string) => {
        const data = this._refApi.basicClientData(term);
        return data;
    }
}
