

import { Component, OnInit, HostListener } from '@angular/core';
import { RiskToleranceService } from 'app/services/riskTolerance.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FinancialService } from 'app/services/financial.service';
import { ReactiveComponent } from '@wtw/toolkit/src/utils/base.component';
import { RunService } from '@wtw/platform/services';

@Component({
    selector: 'risk-tolerance',
    templateUrl: '../../../../node_modules/@acl/rtc-components/src/lib/pages/riskTolerance/riskTolerance.component.html',
    styleUrls: [`../../../../node_modules/@acl/rtc-components/src/lib/pages/riskTolerance/riskTolerance.component.scss`]
})

export class RiskToleranceComponent extends ReactiveComponent implements OnInit {
    public translationKey = 'RTC.RISKTOLERANCE.CONTAINER';
    public translation: any = {
    };
    public pagesArray = Array(4);
    public pageSelected = 0;
    public scrollEvent: Subject<any> = new Subject<any>();
    public scrollEventSubscription: Subscription = new Subscription();

    constructor(private riskToleranceService: RiskToleranceService, private financialService: FinancialService,
        private runService: RunService) {
        super();
    }

    @HostListener('mousewheel', ['$event'])
    onScroll5(e) {
        if (this.scrollEvent.observers.length === 0) {
            this.scrollEvent
                .pipe(debounceTime(100))
                .subscribe(scrollEvent => {
                    if (this.riskToleranceService.scrollEnabled) {
                        this.selectPage(this.pageSelected + (scrollEvent.deltaY > 0 ? 1 : -1));
                    }
                });
        }
        this.scrollEvent.next(e);
    }

    ngOnInit() {
        this._subscriptions.push(this.runService.activeRun.subscribe(c => {
            this.financialService.reloadRun(c);
            this.riskToleranceService.reloadRun(c);
        })
        );
    }

    nextPage() {
        this.pageSelected++;
    }


    selectPage(pageNumber) {
        if (pageNumber >= 0 && pageNumber <= 3) {
            this.pageSelected = pageNumber;
        }
    }

    get dataLoaded() {
        return this.riskToleranceService.riskToleranceLoaded;
    }
}

