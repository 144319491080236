

import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RiskToleranceService } from 'app/services/riskTolerance.service';

@Component({
    selector: 'financingHierarchy-modal',
    templateUrl: '../../../../../../node_modules/@acl/rtc-components/src/lib/pages/riskTolerance/lossThresholds/financingHierarchyModal/financingHierarchyModal.component.html',
    styleUrls: ['../../../../../../node_modules/@acl/rtc-components/src/lib/pages/riskTolerance/lossThresholds/financingHierarchyModal/financingHierarchyModal.component.scss']
})

export class FinancingHierarchyModalComponent implements OnInit {

    @Input() metric: any;
    public translationKey = 'RTC.RISKTOLERANCE.LOSSTHRESHOLDS.FINANCINGHIERARCHY';

    public translation: any = {
    };
    public data: any = {};
    public active = false;
    public form: any;
    public onClose: Subject<boolean>;
    private customValidator = {
        cashAllocation: [Validators.required, Validators.min(0)],
        debtAllocation: [Validators.required, Validators.min(0)],
        interestRate: [Validators.required, Validators.min(0)],
        equityAllocation: [Validators.required, Validators.min(0)]
    };

    constructor(private riskToleranceService: RiskToleranceService, private fb: FormBuilder, public _bsModalRef: BsModalRef) { }

    ngOnInit() {
        this.onClose = new Subject();
        this.form = Object.toFormGroup({
            cashAllocation: this.riskToleranceService.riskTolerance.cashFinancing,
            debtAllocation: this.riskToleranceService.riskTolerance.debtFinancing * 100,
            interestRate: this.riskToleranceService.riskTolerance.interestRate * 100,
            equityAllocation: this.riskToleranceService.riskTolerance.equityFinancing * 100
        }, this.fb, this.customValidator);

    }

    public close() {
        this.hide(null);
    }

    public ok() {
        if (!this.form.valid) {
            this.form.controls.cashFinancing.markAsTouched();
            this.form.controls.debtFinancing.markAsTouched();
            this.form.controls.interestRate.markAsTouched();
            this.form.controls.equityFinancing.markAsTouched();
            return;
        }
        const data = this.form.getRawValue();
        this.riskToleranceService.riskTolerance.cashFinancing = data.cashAllocation;
        this.riskToleranceService.riskTolerance.debtFinancing = data.debtAllocation / 100;
        this.riskToleranceService.riskTolerance.interestRate = data.interestRate / 100;
        this.riskToleranceService.riskTolerance.equityFinancing = data.equityAllocation / 100;
        this.hide(true);
    }

    public cancel() {
        this.hide(false);
    }

    public show() {
        this.active = true;
    }


    private hide(nextVal?: boolean) {
        this.active = false;
        this.onClose.next(nextVal);
        this._bsModalRef.hide();
    }
}
