import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchfilter'
})

@Injectable()
export class SearchFilterPipe implements PipeTransform {
  transform(items: any[], field: any, value: any): any[] {
    if (!items) { return []; }
    return items.filter(it => it[field] === value);
  }
}
