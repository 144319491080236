
/* eslint-disable */
import { CompanyInfo } from './CompanyInfo';
import { FinancialInput } from './FinancialInput';
import { CustomPeerGroup } from './CustomPeerGroup';
import { ScoreIndicatorEnum } from './ScoreIndicatorEnum';

export interface RunViewModel {

	generalClientInfo: CompanyInfo;
	financialInput: FinancialInput;
	periodTypes: PeriodTypeEnum[];
	riskTolerance: RiskToleranceVm;
	estimateTrends: EstimateTrend[];
	enableRiskTolerance: boolean;
}
export interface RiskToleranceVm {

	templatePriorities: TemplatePriority[];
	selectedReportingTemplateId: number;
	customPeerGroup: CustomPeerGroup;
	selectedPeerGroup?: number;
	individualPeers: IndividualPeer[];
	recommendedApplications: number[];
}
export interface TemplatePriority {

	reportingTemplateId: number;
	priorities: PriorityVm[];
	metrics: MetricVm[];
	riskToleranceProperty: PriorityConfiguration;
	riskBearingCapacity: PriorityConfiguration;
	riskAppetite: RiskAppetiteVm;
	cashFinancing: number;
	debtFinancing: number;
	equityFinancing: number;
	interestRate: number;
	defaultPeerGroupId: number;
	customAnalysis: CustomAnalysisVm[];
	periods: PeriodMetricVm[];
}
export interface PriorityConfiguration {

	priorityId: number;
	definition: string;
	deviation?: number;
}
export interface PriorityVm {

	id: number;
	name: string;
	description: string;
	metrics: number[];
	isDefault: boolean;
	isDeleted: boolean;
	min?: number;
	max?: number;
	median?: number;
	severityScore: number;
}
export interface PriorityResultVm {

	min?: number;
	max?: number;
	median?: number;
}
export interface MetricVm {

	id: number;
	formula: string;
	lowDeviation: number;
	highDeviation: number;
	lowOutlier: number;
	highOutlier: number;
	name: string;
	label: string;
	definition: string;
	precision: string;
	consequence: string;
	scoreIndicator: ScoreIndicatorEnum;
	isCustom: boolean;
}
export interface PeriodMetricVm {

	priorityId: number;
	metricId: number;
	period: string;
	currentMetric?: number;
	peerPercentile?: number;
	acceptableDeviation?: number;
	targetMetric?: number;
	riskTolerance?: number;
	isCreditFlag: boolean;
	peerChart: MetricPeerChart;
	individualPeerValues: IndividualPeerValue[];
}
export interface MetricPeerChart {

	minimum: number;
	q1?: number;
	median?: number;
	q3?: number;
	maximum?: number;
}
export interface IndividualPeerValue {

	companyId: number;
	value?: number;
}
export interface RiskAppetiteVm {

	riskAppetite: RiskAppetiteEnum;
	definition: string;
}
export interface CustomAnalysisVm {

	metricId: number;
	priorityId: number;
	selectedForCalculation: boolean;
	periodAnalysis: PeriodAnalysisVm[];
}
export interface PeriodAnalysisVm {

	period: string;
	acceptableDeviation: AcceptableDeviationAnalysis;
	targetMetric: TargetMetricAnalysis;
	riskTolerance: RiskToleranceAnalysis;
}
export interface AcceptableDeviationAnalysis {

	acceptableDeviation?: number;
	isEdit: boolean;
}
export interface TargetMetricAnalysis {

	targetMetric?: number;
	isEdit: boolean;
}
export interface RiskToleranceAnalysis {

	riskTolerance?: number;
	isEdit: boolean;
}
export interface IndividualPeer {

	peerId: number;
	peerName: string;
	dataStatus?: number;
}
export interface EstimateTrend {

	periodType: PeriodTypeEnum;
	companyId: number;
	meanTrend: number;
	highTrend: number;
	lowTrend: number;
}





export enum PeriodTypeEnum {

	annual,

	quarterly,

}

export enum RiskAppetiteEnum {

	aggressive,

	neutral,

	conservative,

}

