/* eslint-disable */

    
    export interface ClientPeer  {
        
		id: number;
		name: string;
    }

    


    
