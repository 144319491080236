
/* eslint-disable */
import * as Dto from '../dtos'; 
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ProxyHttp  } from '@wtw/platform/api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResult,BinaryTransferObject} from '@wtw/platform/api';
import { map } from 'rxjs/operators'; 
export { ApiResult };
import { UrlHelperService } from 'app/shared/helpers/url-helper.service';

@Injectable()
export class CloneRunProxy {
    	constructor(private _phttp: ProxyHttp) { } 
    
    
    cloneRun(model: Dto.RunViewModel, runId: number): Observable<ApiResult<Dto.CloneRunOutput>> {
        return this._phttp.post(false,  `/cloneRun/${runId}`,model||{}).pipe(map((ret:HttpResponse<Dto.CloneRunOutput>) => <ApiResult<Dto.CloneRunOutput>>({  data: ret.body , originalResponse: ret })));
	}
    
}

	
