

import { Component, OnInit } from '@angular/core';
import { FinancialService } from 'app/services/financial.service';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'finiancial-change-settings',
    templateUrl: '../../../../../../node_modules/@acl/rtc-components/src/lib/pages/financials/financialInputs/changeSettings/changeSettings.component.html',
    styleUrls: ['../../../../../../node_modules/@acl/rtc-components/src/lib/pages/financials/financialInputs/changeSettings/changeSettings.component.scss']
})

export class ChangeSettingsComponent implements OnInit {
    public translation: any = {};

    public translationKey: any = 'RTC.FINANCIALS.CHANGESETTINGS';
    public form: any;
    public selectedPeriodType: string;
    public onClose: Subject<boolean>;
    public data: any;
    public referenceData: any;
    private modalActive: boolean;

    constructor(private financialService: FinancialService, public _bsModalRef: BsModalRef) { }

    ngOnInit() {
        this.onClose = new Subject();
        this.financialService.setSettingsForm();
        this.form = this.financialService.settingsForm;
        if (!this.financialService.loadingReferenceData) {
            this.referenceData = this.financialService.referenceData;
        }
        this.setSelectedPeriodType(this.form.controls.periodTypeId.value);
        this.form.controls.periodTypeId.valueChanges.subscribe(value => {
            this.setSelectedPeriodType(value);
        });
    }

    public setSelectedPeriodType(value) {
        this.selectedPeriodType = this.translation['PERIODTYPE' + value];
    }

    public close() {
        this.hide(null);
    }

    public ok() {
        if (!this.form.valid) {
            return;
        }
        this.hide(true);
    }

    public cancel() {
        this.hide(false);
    }

    public show() {
        this.modalActive = true;
    }

    public get active() {
        if (!this.financialService.loadingReferenceData && !this.financialService.referenceData) {
            this.referenceData = this.financialService.referenceData;
        }
        return !this.financialService.loadingReferenceData && this.modalActive;
    }

    private hide(nextVal?: boolean) {
        this.modalActive = false;
        this.data = this.form.getRawValue();
        this.data.projectionValue = !isNaN(this.data.projectionValue) ? this.data.projectionValue / 100 : null;
        this.onClose.next(nextVal);
        this._bsModalRef.hide();
    }
}
