

import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup, FormArray } from '@angular/forms';
import { RiskToleranceService } from 'app/services/riskTolerance.service';
import { CustomPeerGroup } from 'app/api/generated/CustomPeerGroup';
import { DuplicatedIndustryValidator } from 'app/shared/validators/duplicated-industry.validator';

@Component({
    selector: 'custom-peer',
    templateUrl: '../../../../../../node_modules/@acl/rtc-components/src/lib/pages/riskTolerance/lossThresholds/customPeer/customPeer.component.html',
    styleUrls: ['../../../../../../node_modules/@acl/rtc-components/src/lib/pages/riskTolerance/lossThresholds/customPeer/customPeer.component.scss']
})

export class CustomPeerComponent implements OnInit {

    @Output() customPeerGroupClose = new EventEmitter();
    @Input() customPeers: CustomPeerGroup;


    public translationKey = 'RTC.RISKTOLERANCE.PEERGROUP.CUSTOMPEER';
    public translation: any = {};

    public regionalObject: { geographyGroupId: number; geographyId: number; geographyName: string; }[];
    public sectorObject: { industryGroupId: number; industryId: number; industryName: string; }[];
    public industryObject: { industryGroupId: number; industryId: number; industryName: string; }[];
    public form: FormGroup;
    public showWarning = false;
    public assumptions;

    get DuplicatedIndustry(): number[] {
        return DuplicatedIndustryValidator.getDuplicated(this.form.get('industryIds').value);
    }

    private customValidator = {
        industryGroupId: [Validators.required],
        industryIds: [Validators.required],
        sectorIds: [Validators.required],
        geographyIds: [Validators.required],
        rangeMinimum: [Validators.required],
        rangeMaximum: [Validators.required],
    };

    private model: any = {};

    constructor(private riskToleranceService: RiskToleranceService, private fb: FormBuilder) { }

    ngOnInit() {
        this.regionalObject = this.riskToleranceService.peerRefData.geographies;
        this.sectorObject = this.riskToleranceService.peerRefData.industries.filter(c => c.industryGroupId === 4);
        this.industryObject = this.riskToleranceService.peerRefData.industries.filter(c => c.industryGroupId === 2);
        if (!this.customPeers) {
            this.model = {
                industryGroupId: 6,
                industryIds: [],
                sectorIds: this.sectorObject.map(c => false),
                geographyGroupId: 3,
                geographyIds: this.regionalObject.map(c => false),
                financialSize: 1,
                rangeMinimum: null,
                rangeMaximum: null,
                currencyCode: null
            };
        } else {
            this.model = JSON.parse(JSON.stringify(this.customPeers));
            if (!this.model.geographyIds) {
                this.model.geographyIds = this.regionalObject.map(c => false);
            }
            if (!this.model.sectorIds) {
                this.model.sectorIds = this.sectorObject.map(c => false);
            }
            if (this.model.industryIds === undefined) {
                this.model.industryIds = [];
            }
            if (this.model.industryGroupId === 2) {
                this.model.sectorIds = this.sectorObject.map(c => false);
            } else if (this.model.industryGroupId === 4) {
                this.model.sectorIds = this.sectorObject.map(c => this.model.industryIds.find(d => d === c.industryId) !== undefined);
                this.model.industryIds = [];
            } else if (this.model.industryGroupId === 6) {
                this.model.industryIds = [];
            }

            if (this.model.geographyGroupId === 2) {
                this.model.geographyIds =
                    this.regionalObject.map(c => this.model.geographyIds.find(d => d === c.geographyId) !== undefined);
            } else if (this.model.geographyIds === undefined) {
                this.model.geographyIds = this.regionalObject.map(c => false);
            }
        }

        this.form = Object.toFormGroup(this.model, this.fb, this.customValidator);
        this.form.controls.financialSize.valueChanges.subscribe(c => {
            this.form.controls.rangeMinimum.patchValue(null);
            this.form.controls.rangeMaximum.patchValue(null);
        });
        this.assumptions = {
            maxCustomPeers: this.riskToleranceService.peerRefData.maxCustomPeers,
            maxCustomPeersValue: this.riskToleranceService.peerRefData.maxCustomPeers.toLocaleString(),
        };
        this.setIndustryValidator(this.form.get('industryGroupId').value);
        this.form.get('industryGroupId').valueChanges.subscribe(v => {
            this.setIndustryValidator(v);
        });
    }

    setIndustryValidator(v: number) {
        if (v === 2) {
            this.form.get('industryIds').setValidators([Validators.required, DuplicatedIndustryValidator.duplicated]);
        } else {
            this.form.get('industryIds').clearValidators();
        }
        this.form.get('industryIds').updateValueAndValidity();
    }

    addIndustry() {
        (this.form.controls.industryIds as FormArray).push(new FormControl(null, this.customValidator.industryIds));
    }

    removeIndustry(index: number) {
        (this.form.controls.industryIds as FormArray).removeAt(index);
    }

    selectAllSectors() {
        (this.form.controls.sectorIds as FormArray).controls.forEach(c => {
            c.patchValue(true);
        });
    }

    close() {
        this.customPeerGroupClose.emit(null);
    }

    cancel() {
        this.customPeerGroupClose.emit(null);
    }

    ok() {
        (<any>Object).values(this.form.controls).forEach(c => {
            c.markAsTouched();
        });
        const peers = this.form.getRawValue();
        if (!this.form.valid) {
            return;
        } else {
            if (peers.industryGroupId === 6) {
                peers.industryIds = undefined;
                // this.industryError = peers.industryIds.
            }
            if (peers.industryGroupId === 4) {
                peers.industryIds = peers.sectorIds.map((c, i) => c ? this.sectorObject[i].industryId : null).filter(c => c !== null);
            }
            if (peers.geographyGroupId === 2) {
                peers.geographyIds =
                    peers.geographyIds.map((c, i) => c ? this.regionalObject[i].geographyId : null).filter(c => c !== null);
            }
            if (peers.geographyGroupId === 3) {
                peers.geographyIds = undefined;
            }
            peers.currencyCode = this.riskToleranceService.currencyInfo.currentSelectedCurrency;
            this.showWarning = false;
            this.assumptions.currentPeers = null;
            this.assumptions.currentPeersValue = null;
            this.riskToleranceService.validateCustomPeers(peers)
                .uiSignal({ debugInfo: 'Calculating peers', uiLabel: 'Calculating custom peers' }).subscribe(c => {
                    if (c.data && c.data.length > this.assumptions.maxCustomPeers) {
                        this.assumptions.currentPeers = c.data.length.toLocaleString();
                        this.assumptions.currentPeersValue = c.data.length;
                        this.showWarning = true;
                    } else if (c.data && c.data.length > 0) {
                        peers.peers = c.data;
                        this.customPeerGroupClose.emit(peers);
                    } else {
                        document.getElementsByClassName('modal-body')[0].scrollTop = 0;
                        this.showWarning = true;
                    }
                });
        }
    }

    closeWarning() {
        this.showWarning = false;
    }
}
