
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RiskToleranceService } from 'app/services/riskTolerance.service';

@Component({
    selector: 'risk-appetite-modal',
    templateUrl: '../../../../../node_modules/@acl/rtc-components/src/lib/pages/riskTolerance/riskAppetiteModal/riskAppetiteModal.component.html',
    styleUrls: ['../../../../../node_modules/@acl/rtc-components/src/lib/pages/riskTolerance/riskAppetiteModal/riskAppetiteModal.component.scss']
})

export class RiskAppetiteModalComponent implements OnInit {

    public translationKey = 'RTC.RISKTOLERANCE.RISKAPPETITEMODAL';
    public translation: any = {
    };
    public active = true;
    public form: any;
    public data: any = {};
    public onClose: Subject<boolean>;


    private model: {
        definition: string,
        riskAppetite: number
    };
    constructor(private fb: FormBuilder, public _bsModalRef: BsModalRef, private riskToleranceService: RiskToleranceService) { }

    ngOnInit() {
        this.onClose = new Subject();
        this.model = this.riskToleranceService.riskTolerance.riskAppetite;
        this.form = Object.toFormGroup(this.model, this.fb);
    }

    public close() {
        this.hide(null);
    }

    public ok() {
        if (!this.form.valid) {
            return;
        }
        this.hide(true);
    }

    public cancel() {
        this.hide(false);
    }

    public show() {
        this.active = true;
    }

    sanitizeInput() {
        this.data.definition = this.riskToleranceService.sanitizeInput(this.data.definition);
        this.form.controls.definition.setValue(this.data.definition);
    }


    private hide(nextVal?: boolean) {
        this.active = false;
        this.data = this.form.getRawValue();
        this.sanitizeInput();

        this.data.projectionValue = !isNaN(this.data.projectionValue) ? this.data.projectionValue / 100 : null;
        this.onClose.next(nextVal);
        this._bsModalRef.hide();
    }
}
