import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FinancialService } from 'app/services/financial.service';


import * as Highcharts from 'highcharts';
import * as moment from 'moment';

const More = require('highcharts/highcharts-more');
More(Highcharts);

@Component({
    selector: 'financial-overview',
    templateUrl: '../../../../../../node_modules/@acl/rtc-components/src/lib/pages/financials/financialInputs/financialOverview/financialOverview.component.html',
    styleUrls: ['../../../../../../node_modules/@acl/rtc-components/src/lib/pages/financials/financialInputs/financialOverview/financialOverview.component.scss']
})

export class FinancialOverviewComponent implements OnInit, AfterViewInit {
    public translation: any = {
    };
    public translationKey = 'RTC.FINANCIALS.OVERVIEW';
    public model: any = {};
    public totalRevenew: number;
    public saleChange: number;
    private epsMeanId = 100173;
    private epsHighId = 100175;
    private epsLowId = 100176;
    private epsNormalizedId = 100179;
    private employeeId = 3085;
    private historical = [];
    private projection = [];


    constructor(private financialService: FinancialService) { }

    ngOnInit() {
        this.model.companyName = this.financialService.run.generalClientInfo.clientName;
        this.model.line1 = this.financialService.run.generalClientInfo.companyAddress.addressLine1;
        this.model.line2 = this.financialService.run.generalClientInfo.companyAddress.addressLine2;
        this.model.companyType = this.financialService.run.generalClientInfo.dataStatus.dataStatus;

        this.model.sector = this.financialService.run.generalClientInfo.sector;
        this.model.industry = this.financialService.run.generalClientInfo.industry;
        this.model.updatedOn = moment(this.financialService.financialStatement.finScenario.lastModified).format('DD MMM YYYY');
        this.model.dateType = this.financialService.financialStatement.finProjection.periodTypeId === 1 ? '1Y' : '1Q';
    }

    ngAfterViewInit() {
        Promise.resolve(null).then(_ => {
            const rawData = this.financialService.form.getRawValue();
            for (const dataCollectionTypeId in rawData.historical) {
                // eslint-disable-next-line guard-for-in
                for (const section in rawData.historical[dataCollectionTypeId]) {
                    // eslint-disable-next-line guard-for-in
                    for (const dataItem in rawData.historical[dataCollectionTypeId][section]) {
                        this.historical.push(...rawData.historical[dataCollectionTypeId][section][dataItem]);
                        this.projection.push(...rawData.projection[dataCollectionTypeId][section][dataItem]);
                    }
                }
            }
            if (this.model.companyType === 1) {
                this.generateGraph();
            }
            this.model.employees = [...this.historical.filter(d => d.dataItemId === this.employeeId)]
                .sort((a, b) => moment(b.periodEndDate).unix() - moment(a.periodEndDate).unix()).map(d => d.dataItemValue)[0];
            const totalRevenueId = this.financialService.getTotalRenenueDataItemId();
            const revenew = [...this.historical.filter(c => c.dataItemId === totalRevenueId)]
                    .sort((a, b) => moment(b.periodEndDate).unix() - moment(a.periodEndDate).unix()).map(c => c.dataItemValue);
            if (revenew && revenew.length >= 2 && revenew[0] > 0) {
                    this.totalRevenew = revenew[0] * 1e6;
                    this.saleChange = (revenew[0] / revenew[1] - 1);
            }
        });

    }

    private generateGraph() {
        const epsLow = this.getData(this.epsLowId);
        const epsHigh = this.getData(this.epsHighId);
        const epsMean = this.getData(this.epsMeanId);
        const epsNormalized = this.getData(this.epsNormalizedId);
        const actualEpsRange = epsLow.map((c, i) => {
            return [c, epsHigh[i]];
        });
        (<any>Highcharts).Renderer.prototype.symbols.line = function (x, y, width, height) {
            return ['M', x - 10, y + width / 2, 'L', x + height + 10, y + width / 2];
        };

        Highcharts.chart('container', <any>{
            chart: {
                backgroundColor: '#f4f4f4'
            },
            title: {
                text: ''
            },
            credits: {
                enabled: false
            },
            legend: {
                enabled: false
            },
            tooltip: { enabled: false },
            xAxis: {
                // categories: [...this.financialService.historicalDates.map(c => c.substring(0, 4)),
                // ...this.financialService.projectionDates.map(c => c.substring(0, 4))]
                //     .sort((a, b) => (+a === +b) ? 0 : +a < +b ? -1 : 1),
                categories: [...this.financialService.historicalDates,
                ...this.financialService.projectionDates]
                    .sort((a, b) => moment(a).unix() - moment(b).unix())
                    .map(c => `${moment(c).format('MMM YYYY')}`),
            },
            yAxis: {
                title: {
                    enabled: false
                },
            },
            plotOptions: {
                bar: {
                    grouping: false,
                    shadow: false,
                    borderWidth: 0,
                    pointPadding: .25,
                    groupPadding: 0,
                    pointWidth: 20
                },
                scatter: {
                    marker: {
                        symbol: 'line',
                        lineWidth: 3,
                        radius: 9,
                        lineColor: '#FFB81C'
                    }
                },
                series: {
                    pointWidth: 20,
                    states: {
                        inactive: {
                            opacity: 1
                        }
                    }
                }
            },
            series: [{
                type: 'columnrange',
                pointPlacement: 'on',
                color: '#C111A0',
                data: actualEpsRange
            },
            {
                name: 'Target',
                type: 'scatter',
                color: '#FFB81C',
                data: epsMean
            },
            {
                name: 'Warnings',
                type: 'scatter',
                color: '#6A0858',
                marker: {
                    symbol: 'circle',
                    radius: 8,
                    color: '#6A0858',
                    lineWidth: 2,
                    lineColor: '#fff',
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                },
                data: epsNormalized
            }
            ]
        });

    }

    private getData(dataItemId) {
        return [...this.historical.filter(c => c.dataItemId === dataItemId),
        ...this.projection.filter(c => c.dataItemId === dataItemId)]
            .sort((a, b) => moment(a.periodEndDate).unix() - moment(b.periodEndDate).unix()).map(c => c.dataItemValue);
    }
}
