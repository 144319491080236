

import { Component, OnInit } from '@angular/core';
import { RiskToleranceService } from 'app/services/riskTolerance.service';
import { ModalService } from '@wtw/platform/services';
import { RiskAppetiteModalComponent } from '../riskAppetiteModal/riskAppetiteModal.component';
import { TranslateService } from '@ngx-translate/core';
import { IStandardModalConfig, IButtonConfig } from '@wtw/platform/interfaces';
import { ShortNumberFormatPipe } from 'app/pipes/ShortNumberFormatExtenderPipe';
import { Base } from '@wtw/toolkit';


@Component({
    selector: 'define-priorities',
    templateUrl: '../../../../../node_modules/@acl/rtc-components/src/lib/pages/riskTolerance/definePriorities/definePriorities.component.html',
    styleUrls: ['../../../../../node_modules/@acl/rtc-components/src/lib/pages/riskTolerance/definePriorities/definePriorities.component.scss']

})

export class DefinePrioritiesComponent extends Base.ReactiveComponent implements OnInit {
    public translationKey = 'RTC.RISKTOLERANCE.DEFINEPRIORITIES';
    public translation: any = {
        RISKAPPETITE: []
    };
    public priorities: any;
    public metrics: any;
    public prioritiesDictionary: any;
    public model: any;
    public editPriority: any;
    public descriptionDetails: {};
    public missingData: any = {};

    public tabItems =
        [
            {
                key: 'edit',
                displayName: 'Edit',
                translationKey: 'EDIT',
                disabled: () => false,
                event: (priority) => {
                    this.editPriority = priority;
                    this.riskToleranceService.showNavigation = false;
                    this.addActive = true;
                },
            },
            {
                key: 'remove',
                displayName: 'Remove',
                translationKey: 'REMOVE',
                disabled: () => {
                    const x = this.riskToleranceService.riskTolerance.priorities
                        .filter(c => !c.isDefault || (c.isDefault && !c.isDeleted)).length === 1;
                    return x;
                },
                event: (priority) => {
                    if (this.riskToleranceService.riskTolerance.priorities
                        .filter(c => !c.isDefault || (c.isDefault && !c.isDeleted)).length > 1) {
                        this.removePriority(priority);
                    }
                },
            }
        ];
    addActive: boolean;
    constructor(private riskToleranceService: RiskToleranceService, private modalService: ModalService,
        private _translateService: TranslateService, private shortNumberFormatPipe: ShortNumberFormatPipe) {
        super();
    }

    ngOnInit() {
        this.load();
        this._subscriptions.push(this.riskToleranceService.reloadCompleted.subscribe(_ => {
            this.load();
        }));
    }

    load() {
        this.riskToleranceService.showNavigation = true;
        this.model = this.riskToleranceService.riskTolerance;
        this.metrics = this.model.metrics.toDictionary('id');
        this.priorities = JSON.parse(JSON.stringify(this.model.priorities.filter(c => !c.isDeleted)));
        this.prioritiesDictionary = this.priorities.toDictionary('id');
        this.descriptionDetails = {
            priority: this.priorities[0].name,
            RBC: this.shortNumberFormatPipe.transform(this.model.riskBearingCapacity.deviation === null ?
                this.getLossThreshold(this.model.riskBearingCapacity.priorityId).value : this.model.riskBearingCapacity.deviation),
            RTP: this.shortNumberFormatPipe.transform(this.model.riskToleranceProperty.deviation === null ?
                this.getLossThreshold(this.model.riskToleranceProperty.priorityId).value : this.model.riskToleranceProperty.deviation)
        };
        this.missingData = this.priorities.reduce((result, cur) => {
            result[cur.id] = Object.values(this.getLossThreshold(cur.id)).find(d => d === null) !== undefined;
            return result;
        }, {}
        );
    }

    createNewPriority() {
        this.riskToleranceService.showNavigation = false;
        this.addActive = true;
    }

    addPriority(priority) {
        this.editPriority = null;
        this.addActive = false;
        if (priority) {
            this.riskToleranceService.addOrUpdate(priority);
            this.riskToleranceService.saveRun();
        }
        this.load();
    }

    menuItemClicked(item, priority) {
        item.event(priority);
    }

    getLossThreshold(priority) {
        if (isNaN(priority) || priority < 1 || !this.prioritiesDictionary[priority].summaryValues) {
            return {
                value: null,
                min: null,
                max: null
            };
        }

        return {
            value: this.prioritiesDictionary[priority].summaryValues.suggested,
            min: this.prioritiesDictionary[priority].summaryValues.low,
            max: this.prioritiesDictionary[priority].summaryValues.high
        };
    }
    openRiskAppetite() {
        const confirmConfig = {
            class: 'modal-dialog modal-lg'
        };
        this.modalService.confirmWithComponent(RiskAppetiteModalComponent, null, confirmConfig).then((res) => {
            if (res.result === true && res.data) {
                this.riskToleranceService.saveRiskAppetite(res.data);
            }
        });
    }

    removePriority(priority) {
        const yesConfig = <IButtonConfig>{
            display: true,
            text: this._translateService.instant('RTC.RISKTOLERANCE.DEFINEPRIORITIES.REMOVAL.DELETEBUTTON'),
            cssClass: 'btn btn-primary'
        };

        const cancelConfig = <IButtonConfig>{
            display: true,
            text: this._translateService.instant('GLOBAL.BTN.CANCEL')
        };

        const confirmConfig = <IStandardModalConfig>{
            title: this._translateService.instant('RTC.RISKTOLERANCE.DEFINEPRIORITIES.REMOVAL.TITLE', { priority: priority.name }),
            withHeader: true,
            message: this._translateService
                .instant('RTC.RISKTOLERANCE.DEFINEPRIORITIES.REMOVAL.' + (priority.isDefault ? 'MESSAGE1' : 'MESSAGE2')),
            yesButton: yesConfig,
            noButton: cancelConfig
        };
        this.modalService.confirm(confirmConfig).then(
            (c) => {
                if (c) {
                    this.riskToleranceService.removePriority(priority);
                    this.riskToleranceService.saveRun();
                    this.load();
                }
            }
        );
    }
}
