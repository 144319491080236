import { Injectable, Injector } from '@angular/core';
import { RunService } from '@wtw/platform/services';
import {
    RunViewModel,
    FinancialData,
    ScenarioData,
    RiskAppetiteVm, PriorityConfiguration, TemplatePriority, ReferenceDataProxy, ScenarioProxy,
    PeersData, PriorityVm, IndividualPeer, RiskToleranceProxy, ApiResult, PeriodMetricVm, RiskAppetiteEnum
} from 'app/api/dtos';
import { RunInfo, CurrencyInfo, RunModel } from '@wtw/platform/api/dtos';
import { TranslateService } from '@ngx-translate/core';
import { switchMap, map } from 'rxjs/operators';
import { CustomPeerGroup } from 'app/api/generated/CustomPeerGroup';
import { Observable, of, Subject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { Validators } from '@angular/forms';

@Injectable()
export class RiskToleranceService {




    public tabHeaders: { key: string, selected: boolean }[] = [];
    public showCompanyType = true;
    public tabDictionary = {};
    public defaultProjections: any[];
    public form: any;
    public settingsForm: any;
    public historicalDates: string[] = [];
    public projectionDates: string[] = [];
    public financialStatement: ScenarioData;
    public savedStatements: any;
    public savedStatementsLoaded: any;
    public referenceData: any;
    public loadingReferenceData: boolean;
    public run: RunViewModel;
    public riskTolerance: TemplatePriority;
    public riskToleranceLoaded = false;
    public showNavigation = true;
    public scrollEnabled = true;
    public refData: any;
    public peerRefData: PeersData;
    public statementLoaded: boolean;
    newMetricId: number;

    public historical: FinancialData[];
    public projection: FinancialData[];
    public currencyInfo: CurrencyInfo;
    public prioritiesChanged = new Subject<any>();
    public reloadCompleted = new Subject<any>();
    public metricNames: {
        label: string,
        id: number
    }[];
    public runInfo: RunInfo;

    public reloadInProgress: boolean;
    private _runService: RunService;
    private _translate: TranslateService;
    private _referenceDataProxy: ReferenceDataProxy;
    private _scenarioServiceProxy: ScenarioProxy;
    private _riskTolerenceProxy: RiskToleranceProxy;


    constructor(private inj: Injector, private _sanitizer: DomSanitizer) {


    }

    public init() {
        setTimeout(() => {
            this._runService = this.inj.get(RunService);
            this._translate = this.inj.get(TranslateService);
            this._referenceDataProxy = this.inj.get(ReferenceDataProxy);
            this._scenarioServiceProxy = this.inj.get(ScenarioProxy);
            this._riskTolerenceProxy = this.inj.get(RiskToleranceProxy);


            this._runService.currencyConverted.pipe(switchMap(c => {
                return this._scenarioServiceProxy.
                    updateCurrency(c.runId, c.currencyInfo.currentSelectedCurrency).uiSignal('Loading')
                    .pipe(map(_ => c));
            })).subscribe(run => {
                const model = run.data as RunViewModel;
                if (model.riskTolerance.selectedReportingTemplateId > 0) {
                    if (run.data.riskTolerance.customPeerGroup) {
                        run.data.riskTolerance.customPeerGroup.currencyCode = run.currencyInfo.currentSelectedCurrency;
                    }
                    this._runService
                        .executeAlgorithmAsync(run.runId, run.data, 'calc_peer_analysis', run.currencyInfo)
                        .uiSignal({ debugInfo: 'Calling Trigger', uiLabel: this._translate.instant('GLOBAL.BUSY.LOADING') }).subscribe();
                }
            });
        });
    }

    public reloadRun(run: RunModel) {
        this.statementLoaded = false;
        this.runInfo = run.info;
        this.currencyInfo = run.currencyInfo;
        this.run = run.data;
        if (this.run.riskTolerance.selectedPeerGroup) {
            this.run.enableRiskTolerance = true;
            this.riskTolerance = this.run.riskTolerance.templatePriorities
                .find(d => d.reportingTemplateId === this.run.riskTolerance.selectedReportingTemplateId);

            this._scenarioServiceProxy.scenarioData(this.run.financialInput.selectedScenarioId).uiSignal('Loading').subscribe(e => {
                this.statementLoaded = true;
                this.financialStatement = e.data;
            });
            this._referenceDataProxy.definedPriorityReferenceData(this.run.financialInput.selectedScenarioId).subscribe(d => {
                this.refData = d.data;
                const inputTranslation = this._translate.instant('RTC.FINANCIALS.INPUTS');
                this.refData.templateDataItems = this.refData.templateDataItemIds.map((e: any) => {
                    return {
                        id: e,
                        name: inputTranslation[`DATAITEM${e}`]
                    };

                });
            });
            if (!this.peerRefData) {
                this._referenceDataProxy.peersReferenceData().uiSignal('').subscribe(d => {
                    this.peerRefData = d.data;
                    this.peerRefData.peerGroups.push({
                        peerGroupId: 1000,
                        peerGroupName: 'Custom Peer Group'
                    } as any);
                    Promise.resolve(null).then(cc =>
                        this.riskToleranceLoaded = true
                    );
                });
            } else {
                this.riskToleranceLoaded = true;
            }

            const metrics = (this.riskTolerance.metrics as any).toDictionary('id');
            this.riskTolerance.priorities.forEach((cc: PriorityVm) => {
                cc.metrics.forEach(d => {
                    metrics[d].riskTolerance = this.getMetricPeriods(cc.id, d)
                        .map((e: { riskTolerance: any; }) => e.riskTolerance).reduce((acc: any, cur: any) => acc + cur, 0);
                });
                (cc as any).metrics
                    .sort((a: string | number, b: string | number) => metrics[a].riskTolerance / this.getMetricPeriods(cc.id, a).length -
                        metrics[b].riskTolerance / this.getMetricPeriods(cc.id, b).length);
            });
            this.riskTolerance.priorities.sort((a, b) => a.median - b.median);
            this.reloadInProgress = false;
            this.updateAllPrioritiesSummaryValue();
            this.reloadCompleted.next(true);
        }
    }

    public calltrigger(triggerName: string) {
        if ((this.riskTolerance && this.riskTolerance.customAnalysis) || triggerName === 'calc_peer_analysis') {
            return this._runService
                .executeAlgorithmAsync(this.runInfo.runId, this.run, triggerName, this.currencyInfo)
                .uiSignal({ debugInfo: 'Calling Trigger', uiLabel: this._translate.instant('GLOBAL.BUSY.LOADING') });
        }
        return of(null);
    }

    public saveRiskAppetite(riskAppetite: RiskAppetiteVm) {
        if (JSON.stringify(this.riskTolerance.riskAppetite) !== JSON.stringify(riskAppetite)) {
            this.riskTolerance.riskAppetite = riskAppetite;
            this.riskToleranceLoaded = false;
            this._runService.persist(this.runInfo.runId, this.run, this.currencyInfo, null, null, null, true);
        }
    }

    public saveSummaryPriority(modalSelected: number, priorityConfiguration: PriorityConfiguration) {
        if (modalSelected === 1) {
            if (JSON.stringify(this.riskTolerance.riskToleranceProperty) !== JSON.stringify(priorityConfiguration)) {
                this.riskTolerance.riskToleranceProperty = priorityConfiguration;
                this.riskToleranceLoaded = false;
                this._runService.persist(this.runInfo.runId, this.run, this.currencyInfo, null, null, null, true);
            }
        } else if (modalSelected === 2) {
            if (JSON.stringify(this.riskTolerance.riskBearingCapacity) !== JSON.stringify(priorityConfiguration)) {
                this.riskTolerance.riskBearingCapacity = priorityConfiguration;
                this.riskToleranceLoaded = false;
                this._runService.persist(this.runInfo.runId, this.run, this.currencyInfo, null, null, null, true);
            }
        }

    }

    public getMetricPeriods(priorityId: number, metricId: any) {
        return this.getCombinedMetricPeriod(priorityId, metricId);
    }

    public getMetricRiskTolerance(priorityId: any, metricId: any): number[] {
        return this.getCombinedMetricPeriod(priorityId, metricId)
            .filter((c: { riskTolerance: number; }) => c.riskTolerance !== null && c.riskTolerance !== undefined && c.riskTolerance >= 0)
            .map((cm: { riskTolerance: any; }) => cm.riskTolerance);
    }

    public getSortedTolerancePeriod(priorityId: any, metricId: any) {
        return this.getCombinedMetricPeriod(priorityId, metricId)
            .sort((a: { period: string | number | Date; },
                b: { period: string | number | Date; }) => new Date(b.period).valueOf() - new Date(a.period).valueOf());
    }

    public getLatestTolerancePeriodValue(priorityId: any, metricId: any): number {
        const sortedPeriod = this.getSortedTolerancePeriod(priorityId, metricId)
        .filter((c: { riskTolerance: number; }) => c.riskTolerance !== null &&
            c.riskTolerance !== undefined && c.riskTolerance >= 0)
            .map((p: { riskTolerance: any; }) => p.riskTolerance);
        return sortedPeriod.length > 0 ? sortedPeriod[0] : null;
    }

    public getCreditFlagForMetricPriority(priorityId: any, metricId: any): boolean {
        const metricPeriods = JSON.parse(JSON.stringify(this.riskTolerance.periods.filter(c => c.priorityId === +priorityId
            && c.metricId === +metricId)));

        return metricPeriods.some((x: { isCreditFlag: boolean; }) => x.isCreditFlag === true);
    }

    public getSuggesteMetricByPeriod(priorityId: string | number, metricId: string | number) {
        const metricPeriods = JSON.parse(JSON.stringify(this.riskTolerance.periods.filter(c => c.priorityId === +priorityId
            && c.metricId === +metricId)));
        return metricPeriods
            .map((e: PeriodMetricVm) => <PeriodMetricVm>{
                ...e,
                period: e.period,
                riskTolerance: e.riskTolerance,
                targetMetric: e.targetMetric,
                acceptableDeviation: e.acceptableDeviation
            }).sort((a: { period: string | number | Date; },
                b: { period: string | number | Date; }) => new Date(a.period).valueOf() - new Date(b.period).valueOf());
    }

    public removePriority(priority: { id: number; }) {

        this.riskTolerance.priorities.forEach((c, i) => {
            if (c.id === priority.id) {
                if (!c.isDefault) {
                    this.riskTolerance.priorities.splice(i, 1);
                } else {
                    c.isDeleted = true;
                }
            }
        });

        const priorities = JSON.parse(JSON.stringify(this.riskTolerance.priorities))
        .filter((c: { isDeleted: boolean; }) => c.isDeleted === false);
        priorities.sort((a: { median: number; }, b: { median: number; }) => b.median - a.median);
        if (this.riskTolerance.riskBearingCapacity.priorityId === priority.id) {
            this.riskTolerance.riskBearingCapacity.priorityId = null;
        }
        if (this.riskTolerance.riskToleranceProperty.priorityId === priority.id) {
            this.riskTolerance.riskToleranceProperty.priorityId = null;
        }

        if (this.riskTolerance.riskBearingCapacity.priorityId === null
            && this.riskTolerance.riskToleranceProperty.priorityId === null) {
            const exists = priorities.find((c: { isDeleted: boolean; }) => c.isDeleted === false);
            if (exists) {
                this.riskTolerance.riskToleranceProperty.priorityId = exists.id;
                this.riskTolerance.riskBearingCapacity.priorityId = exists.id;
            }
        }
        if (this.riskTolerance.riskToleranceProperty.priorityId === null) {
            if (priorities.length === 1) {
                this.riskTolerance.riskToleranceProperty.priorityId = priorities[0].id;
            } else {
                const exists = priorities
                .find((c: { id: number; isDeleted: boolean; }) => c.id !== this.riskTolerance.riskBearingCapacity.priorityId
                    && c.isDeleted === false);
                if (exists) {
                    this.riskTolerance.riskToleranceProperty.priorityId = exists.id;
                }
            }
        }
        if (this.riskTolerance.riskBearingCapacity.priorityId === null) {
            if (priorities.length === 1) {
                this.riskTolerance.riskBearingCapacity.priorityId = priorities[0].id;
            } else {
                const exists = this.riskTolerance.priorities
                .find(c => c.id !== this.riskTolerance.riskToleranceProperty.priorityId
                    && c.isDeleted === false);
                if (exists) {
                    this.riskTolerance.riskBearingCapacity.priorityId = exists.id;
                }
            }
        }

    }

    public addOrUpdate(priority: PriorityVm) {
        const exists = this.riskTolerance.priorities.find(c => c.id === priority.id);
        if (exists) {
            exists.max = priority.max;
            exists.min = priority.min;
            exists.median = priority.median;
            exists.metrics = priority.metrics;
            exists.name = priority.name;
            exists.severityScore = priority.severityScore;
        } else {
            this.riskTolerance.priorities.push(priority);
        }
        // this.updateAllPrioritiesSummaryValue();
    }

    public saveRun(reload = true) {
        if (reload) {
            this.riskToleranceLoaded = false;
            this._runService.persist(this.runInfo.runId, this.run, this.currencyInfo, null, null, null, true);
        } else {
            this._runService.persist(this.runInfo.runId, this.run, this.currencyInfo);
        }
    }


    removeMetric(metric: { id: number; }) {
        this.riskTolerance.metrics.forEach((c, i) => {
            if (c.id === metric.id) {
                this.riskTolerance.metrics.splice(i, 1);
            }
        });
    }

    setUniqueMetricNames() {
        this.metricNames = this.riskTolerance.metrics.map(c => ({
            label: c.label,
            id: c.id
        }));
    }

    updatePeer(selectedPeer: number, individualPeers: IndividualPeer[], customPeer: CustomPeerGroup) {
        this.run.riskTolerance.selectedPeerGroup = selectedPeer;
        this.run.riskTolerance.individualPeers = individualPeers;
        if (selectedPeer === 1000) {
            this.run.riskTolerance.customPeerGroup = customPeer;
            this.run.riskTolerance.customPeerGroup.currencyCode = this.currencyInfo.currentSelectedCurrency;
        } else {
            this.run.riskTolerance.customPeerGroup = null;
        }
    }

    validateCustomPeers(peers: any): Observable<ApiResult<number[]>> {
        return this._riskTolerenceProxy.numberOfCompanies(peers);
    }

    updateAllPrioritiesSummaryValue() {
        this.riskTolerance.priorities.forEach(c => {
            const riskToleranceValues = c.metrics.map(d => this.getCombinedMetricPeriod(c.id, d).
                sort((a: { period: string | number | Date; }, b: { period: string | number | Date; }) => new Date(b.period)
                .valueOf() - new Date(a.period).valueOf())
                .find((e: { riskTolerance: number; }) => e.riskTolerance !== null && e.riskTolerance !== undefined && e.riskTolerance >= 0))
                .filter(x => x);
            if (riskToleranceValues && riskToleranceValues.length > 0) {
                (c as any).summaryValues = this.calcSummaryValue(riskToleranceValues.map(x => x.riskTolerance)
                    , this.riskTolerance.riskAppetite.riskAppetite);
            } else {
                (c as any).summaryValues = {};
            }
        });
        this.riskTolerance.priorities = this.riskTolerance.priorities.
            sort((a: any, b: any) => a.summaryValues.suggested - b.summaryValues.suggested);
        this.prioritiesChanged.next(null);
    }

    pascalTriangle(numRows: number) {
        if (numRows === 0) { return []; }
        if (numRows === 1) { return [1]; }
        const result = [];
        for (let row = 1; row <= numRows; row++) {
            const arr = [];
            for (let col = 0; col < row; col++) {
                if (col === 0 || col === row - 1) {
                    arr.push(1);
                } else {
                    arr.push(result[row - 2][col - 1] + result[row - 2][col]);
                }
            }
            result.push(arr);
        }
        return result[numRows - 1];
    }

    calcSummaryValue(riskToleranceValues: any[], riskAppetite: RiskAppetiteEnum) {
        riskToleranceValues = riskToleranceValues.sort((a: number, b: number) => a - b);
        const n = riskToleranceValues.length;
        const pascals = this.pascalTriangle(n);
        const sumPascals = pascals.reduce((a: any, b: any) => a + b, 0);
        const pt = pascals.map((c: number) => c / sumPascals);
        const vec = Array.from(Array(n).keys()).map((c) => (c + 1) * (c + 1));

        const cons_multiplier = vec.map(c => c).reverse();
        const cons_multiplierSum = cons_multiplier.reduce((a, b, i) => a + b * pt[i], 0);
        const agg_multiplier = vec;
        const agg_multiplierSum = agg_multiplier.reduce((a, b, i) => a + b * pt[i], 0);
        const neutral_multiplier = Array.from(Array(n).keys()).map((c) => 1);
        const neutral_multiplierSum = neutral_multiplier.reduce((a, b, i) => a + b * pt[i], 0);

        const cons_weight = cons_multiplier.map(
            (c, i) => (c * pt[i]) / cons_multiplierSum
        );
        const agg_weight = agg_multiplier.map(
            (c, i) => (c * pt[i]) / agg_multiplierSum
        );
        const neutral_weight = neutral_multiplier.map(
            (c, i) => (c * pt[i]) / neutral_multiplierSum
        );

        const cons_point = cons_weight.reduce((a, b, i) => a + b * riskToleranceValues[i], 0);
        const agg_point = agg_weight.reduce((a, b, i) => a + b * riskToleranceValues[i], 0);
        const neutral_point = neutral_weight.reduce((a, b, i) => a + b * riskToleranceValues[i], 0);
        const min_point = Math.min(...riskToleranceValues);
        const max_point = Math.max(...riskToleranceValues);

        if (riskAppetite === RiskAppetiteEnum.conservative) {
            return {
                suggested: cons_point,
                high: neutral_point,
                low: min_point
            };
        } else if (riskAppetite === RiskAppetiteEnum.neutral) {
            return {
                suggested: neutral_point,
                high: agg_point,
                low: cons_point
            };
        } else {
            return {
                suggested: agg_point,
                high: max_point,
                low: neutral_point
            };
        }

    }

    getNewMetricId(): number {
        if (!this.newMetricId) {
            this.newMetricId = Math.max(...this.riskTolerance.metrics.map(c => c.id), 999);
        }
        return ++this.newMetricId;
    }

    public GetProjectionDates() {
        return Object.keys(this.groupBy(this.financialStatement.projectionData, 'periodEndDate')).sort((a, b) => {
            const date1 = new Date(a);
            const date2 = new Date(b);
            return date2.valueOf() - date1.valueOf();
        }
        ).slice(0, 2).map(c => c);
    }


    public tokenize(s: string,
        parsers: { [x: string]: { exec: (arg0: any) => any; }; operands?: RegExp; operators?: RegExp; numbers?: RegExp; },
        deftok: string) {
        let m: number, r: any[], t: any;
        const tokens = [];
        while (s) {
            t = null;
            m = s.length;
            Object.keys(parsers).forEach(key => {
                r = parsers[key].exec(s);
                let itemValue = r ? r[0] : '';
                if (r && key === 'operands') {
                    const find = this.refData.templateDataItems
                    .find((c: { id: number; }) => c.id === +r[0].replace('{', '').replace('}', ''));
                    if (find) {
                        itemValue = find.name;
                    }
                }
                if (r && (r['index'] < m)) {
                    t = {
                        token: r[0],
                        type: key,
                        matches: r.slice(1),
                        itemValue: itemValue,
                        inView: false,
                        dropdown: []
                    };
                    m = r['index'];
                }
            });
            if (m) {
                tokens.push({
                    token: '',
                    type: deftok || 'unknown',
                    itemValue: s.substr(0, m).trim(),
                    inView: true,
                    dropdown: []
                });
            }
            if (t) {
                tokens.push(t);
            }
            s = s.substr(m + (t ? t.token.length : 0));
        }
        return tokens;
    }

    nativeGlobal() { return window; }

    groupBy(xs: any[], key: string) {
        return xs.reduce((rv: { [x: string]: any[]; }, x: { [x: string]: string | number; }) => {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    }

    public sanitizeInput(value: string): any {
        return this._sanitizer.sanitize(1, value).trim();
    }

    public regexPatternValidator(): any {
        return Validators.pattern('^[a-zA-Z0-9 ]+');
    }

    private getCombinedMetricPeriod(priorityId: number, metricId: number) {
        const customAux = this.riskTolerance.customAnalysis != null ?
            this.riskTolerance.customAnalysis.find(x => x.metricId === +metricId
                && x.priorityId === +priorityId
                && x.periodAnalysis.some(y => y.riskTolerance
                    && y.riskTolerance.riskTolerance != null)) : null;
        let metricPeriods = JSON.parse(JSON.stringify(this.riskTolerance.periods.filter(c => c.priorityId === +priorityId
            && c.metricId === +metricId)));
        if (customAux && customAux.periodAnalysis.length > 0) {
            metricPeriods = metricPeriods.toDictionary('period');
            return customAux.periodAnalysis.map(p => <PeriodMetricVm>{
                ...metricPeriods[p.period],
                period: p.period,
                riskTolerance: p.riskTolerance.riskTolerance,
                targetMetric: p.targetMetric.targetMetric,
                acceptableDeviation: p.acceptableDeviation.acceptableDeviation,
                customAnalysis: true
            })
                .sort((a, b) => new Date(a.period).valueOf() - new Date(b.period).valueOf());
        } else {
            return metricPeriods
                .map((e: PeriodMetricVm) => <PeriodMetricVm>{
                    ...e,
                    period: e.period,
                    riskTolerance: e.riskTolerance,
                    targetMetric: e.targetMetric,
                    acceptableDeviation: e.acceptableDeviation
                }).sort((a: { period: string | number | Date; },
                    b: { period: string | number | Date; }) => new Date(a.period).valueOf() - new Date(b.period).valueOf());
        }
    }
}
