import './services/arrayExtender';
import './services/objectExtender';

import { HttpClientModule } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PlatformRootComponent } from '@wtw/platform';

import { CommonModule } from './common.module';

export declare let require: any;

@NgModule({
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        HttpClientModule,
    ],
    bootstrap: [PlatformRootComponent],
    declarations: [],
    //entryComponents: [...MainComponents],
    schemas: [CUSTOM_ELEMENTS_SCHEMA] //ignore custom component tag in html
})
export class AppModule { }
