
interface Array<T> {
    groupBy(key: string): { [key: string]: T[] };
    toDictionary(key: string): { [key: string]: T };
    empty(length: number): number[];
    flatten(): any[];
}

Array.prototype.groupBy = function <T>(key): { [key: string]: T[] } {
    return this.reduce((objectsByKeyValue, obj) => {
        const value = obj[key];
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
    }, {});
};

Array.prototype.toDictionary = function <T>(key): { [key: string]: T } {
    const aux = JSON.parse(JSON.stringify(this));
    return aux.reduce((objectsByKeyValue, obj) => {
        const value = obj[key];
        delete obj[key];
        objectsByKeyValue[value] = obj;
        return objectsByKeyValue;
    }, {});
};

Array.prototype.empty = function <T>(length): number[] {
    return Array.from({ length: length }, (v, i) => i);
};

Array.prototype.flatten = function <T>() {
    const result = [];
    for (let i = 0, length = this.length; i < length; i++) {
        const value = this[i];
        if (Array.isArray(value)) {
            result.push(...value.flatten());
        } else {
            result.push(value);
        }
    }
    return result;
};
