export interface  ClientInfo  {

      name: string;
      dunsId: string;
      tickerCode: string;
      revenue: number;
      marketCap: number;
      annualIncome: number;
      numberofEmployees: number;
      city: string;
      isoCountry2: string;
      asAtDate: Date;
      streetAddress: string;
      id: number;
      exchangeCode: string;

    }
