
/* eslint-disable */
import * as Dto from '../dtos';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ProxyHttp } from '@wtw/platform/api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResult, BinaryTransferObject } from '@wtw/platform/api';
import { map } from 'rxjs/operators';
export { ApiResult };
import { UrlHelperService } from 'app/shared/helpers/url-helper.service';

@Injectable()
export class FinancialInformationProxy {
    constructor(private _phttp: ProxyHttp) { }


    getHistoricalFinancialInformation(scenarioId?: number): Observable<ApiResult<Dto.FinancialData[]>> {
        return this._phttp.get(false, `/financial/historical/${scenarioId}`, null || {}).pipe(map((ret: HttpResponse<Dto.FinancialData[]>) => <ApiResult<Dto.FinancialData[]>>({ data: ret.body, originalResponse: ret })));
    }
    getProjectionInformation(scenarioId?: number): Observable<ApiResult<Dto.FinancialData[]>> {
        return this._phttp.get(false, `/financial/projection/${scenarioId}`, null || {}).pipe(map((ret: HttpResponse<Dto.FinancialData[]>) => <ApiResult<Dto.FinancialData[]>>({ data: ret.body, originalResponse: ret })));
    }

}


