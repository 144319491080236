
/* eslint-disable */
import { ReportingTemplate } from './ReportingTemplate';
import { ProjectionType } from './ProjectionType';
import { AccountingStandard } from './AccountingStandard';

export interface FinancialData {

	dataItemId: number;
	dataItemValue?: number;
	periodEndDate?: Date;
	sectionNumber: number;
	lineOrder: number;
	dataCollectionTypeId: number;
	isBold?: boolean;
	footnote: string;
	isMandatory?: boolean;
	formatPrecision: string;
	scalesFlag: boolean;
	isCurrency: boolean;
}
export interface ScenarioData {

	finScenario: FinScenario;
	historicalData: FinancialData[];
	projectionData: FinancialData[];
	finHistorical: FinHistorical;
	finProjection: FinProjection;
}
export interface FinScenario {

	finScenarioId: number;
	scenarioName: string;
	createdDate: Date;
	lastModified: Date;
	savedByUser: boolean;
	projectionApplies: boolean;
	currentlySelected: boolean;
}
export interface FinHistorical {

	reportingTemplateTypeId: number;
	periodTypeId: number;
	accountingStandardId: number;
	currencyCode: string;
	companyId: number;
	runId: number;
	linkedDisplayId: number;
}
export interface FinProjection {

	projectionTypeId: number;
	projectionValue?: number;
	projectionLength?: number;
	reportingTemplateTypeId: number;
	periodTypeId: number;
	accountingStandardId: number;
}
export interface FinancialSettingData {

	reportingTemplates: ReportingTemplate[];
	projectionTypes: ProjectionType[];
	accountingStandards: AccountingStandard[];
	totalRevenueHandleItems: TotalRevenueHandleItem[];
}
export interface TotalRevenueHandleItem {

	reportingTemplateTypeId: number;
	dataItemId: number;
}





