
/* eslint-disable */


export interface FinancialInput {

  selectedScenarioId: number;
}





