
import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { RiskToleranceService } from 'app/services/riskTolerance.service';
import { TranslateService } from '@ngx-translate/core';
import { create, all } from 'mathjs';
import { IButtonConfig, IStandardModalConfig } from '@wtw/platform/interfaces';
import { ModalService } from '@wtw/platform/services';
import { ShortNumberFormatPipe } from 'app/pipes/ShortNumberFormatExtenderPipe';

@Component({
    selector: 'priorities-modal',
    templateUrl: '../../../../../../node_modules/@acl/rtc-components/src/lib/pages/riskTolerance/definePriorities/prioritiesModal/prioritiesModal.component.html',
    styleUrls: ['../../../../../../node_modules/@acl/rtc-components/src/lib/pages/riskTolerance/definePriorities/prioritiesModal/prioritiesModal.component.scss']
})

export class PrioritiesModalComponent implements OnInit, OnDestroy {
    @Input() priority: any = {};
    public translationKey = 'RTC.RISKTOLERANCE.DEFINEPRIORITIESMODAL';
    public editMetrics = false;
    public translation: any = {
    };

    public prioritySelectOpt: any = [];
    public priorities: any;
    public prioritiesArray: any;
    public defaultMetrics: any;
    public customMetrics: any;
    public riskToleranceService: RiskToleranceService; // TODO: change to private prop
    public model = {
        selectedPriority: null,
        priorityName: '',
        showCustomName: false,
        severity: null
        // metricList: [false, false, false, false, false]
    };

    public rangeValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    public customValidator = {
        priorityName: [Validators.required, Validators.pattern('^[a-zA-Z0-9 ]+'), 
        this.uniqueNameValidator(this._riskToleranceService)],
        selectedPriority: [Validators.required],
    };

    public tabItems =
        [
            {
                key: 'edit',
                displayName: 'Edit',
                translationKey: 'EDIT',
                disabled: () => false,
                event: (metric) => {
                    this.editMetrics = true;
                    this.selectedMetric = metric;
                }
            }
        ];

    public customMetricTabItems =
        [
            {
                key: 'edit',
                displayName: 'Edit',
                translationKey: 'EDIT',
                disabled: () => false,
                event: (metric) => {
                    this.editMetrics = true;
                    this.selectedMetric = metric;
                },
            },
            {
                key: 'remove',
                displayName: 'Remove',
                translationKey: 'REMOVE',
                disabled: () => {
                    const x = this.riskToleranceService.riskTolerance.priorities
                        .filter(c => !c.isDefault || (c.isDefault && !c.isDeleted)).length === 1;
                    return x;
                },
                event: (metric) => {
                    this.removeCustomMetric(metric);
                },
            }
        ];

    @Output() addClosed = new EventEmitter();
    form: any;
    selectDisabled: boolean;
    selectedMetric: any;

    private math: any;
    private originalPriority;
    private originalMetrics;


    constructor(private _riskToleranceService: RiskToleranceService, private fb: FormBuilder, private translateService: TranslateService,
        private modalService: ModalService, private shortNumberPipe: ShortNumberFormatPipe) {
        this.riskToleranceService = _riskToleranceService;
    }

    ngOnInit() {
        this.model.severity = this.riskToleranceService.refData.defaultSeverityScore;
        const config = {};
        this.math = create(all, config);
        this.riskToleranceService.scrollEnabled = false;
        this.priorities = this.riskToleranceService.riskTolerance.priorities.toDictionary('id');
        this.prioritiesArray = this.riskToleranceService.riskTolerance.priorities.sort((a, b) => a.median - b.median);
        this.defaultMetrics = JSON.parse(JSON.stringify(this.riskToleranceService.riskTolerance.metrics.filter(c => !c.isCustom)));
        this.riskToleranceService.setUniqueMetricNames();
        this.customMetrics = JSON.parse(JSON.stringify(this.riskToleranceService.riskTolerance.metrics.filter(c => c.isCustom)));

        if (this.priority) {
            this.selectDisabled = true;
            this.model.selectedPriority = this.priority.id;
            this.model.priorityName = this.priority.name;
            this.model.severity = this.priority.severityScore;
            if (!this.priority.isDefault) {
                this.model.showCustomName = true;
            }
            this.defaultMetrics.forEach(c => {
                if (this.priority.metrics.find(d => d === c.id)) {
                    c.selected = true;
                } else {
                    c.selected = false;
                }
            });
            this.customMetrics.forEach(c => {
                if (this.priority.metrics.find(d => d === c.id)) {
                    c.selected = true;
                } else {
                    c.selected = false;
                }
            });
            const numberOfMetrics = this.customMetrics.filter(c => c.selected).length +
                this.defaultMetrics.filter(c => c.selected).length;
            this.priority.canAddMetric = numberOfMetrics < 5 ? true : false;
            this.priority.canRemoveMetric = numberOfMetrics > 1 ? true : false;
        } else {
            this.priority = {
                id: -1,
                name: '',
                metrics: [],
                isDefault: false,
                isDeleted: false,
                canAddMetric: true,
                canRemoveMetric: true,
                severityScore: 5
            };
            this.prioritySelectOpt = [
                {
                    name: this.translateService.instant(this.translationKey + '.SELECTDEFAULTPRIORITYLABEL'),
                    priorities: this.riskToleranceService.riskTolerance.priorities.filter(c => c.isDefault && c.isDeleted).map(c => {
                        return {
                            name: c.name,
                            id: c.id
                        };
                    })
                },
                {
                    name: this.translateService.instant(this.translationKey + '.SELECTCUSTOMPRIORITYLABEL'),
                    priorities: [{
                        name: this.translateService.instant(this.translationKey + '.ADDCUSTOMPRIORITYLABEL'),
                        id: -1
                    }]
                }
            ];
            this.defaultMetrics.forEach(c => {
                c.selected = false;
            });
            this.customMetrics.forEach(c => {
                c.selected = false;
            });
        }

        [...this.defaultMetrics, ...this.customMetrics].forEach(c => this.metricCanEvaluate(c));

        this.form = Object.toFormGroup(this.model, this.fb, this.customValidator);
        this.form.controls.selectedPriority.valueChanges.subscribe(value => {
            if (value === -1) {
                this.form.controls.priorityName.setValue('', false);
                this.priority = {
                    id: -1,
                    name: '',
                    metrics: [],
                    isDefault: false,
                    isDeleted: false,
                    canAddMetric: true,
                    canRemoveMetric: true,
                    severityScore: 5
                };
                this.model.showCustomName = true;
                this.model.selectedPriority = this.priority.id;
                this.model.priorityName = this.priority.name;
            } else {
                this.priority = this.riskToleranceService.riskTolerance.priorities.find(c => c.id === value);
                this.model.showCustomName = false;
                this.model.selectedPriority = this.priority.id;
                this.model.priorityName = this.priority.name;
            }
            this.form.controls.priorityName.setValue(this.model.priorityName, false);
            if (!this.priority.isDefault) {
                this.model.showCustomName = true;
            }
            this.defaultMetrics.forEach(c => {
                if (this.priority.metrics.find(d => d === c.id)) {
                    c.selected = true;
                } else {
                    c.selected = false;
                }
            });
            this.customMetrics.forEach(c => {
                if (this.priority.metrics.find(d => d === c.id)) {
                    c.selected = true;
                } else {
                    c.selected = false;
                }
            });
            const numberOfMetrics = this.customMetrics.filter(c => c.selected).length +
                this.defaultMetrics.filter(c => c.selected).length;
            this.priority.canAddMetric = numberOfMetrics < 5 ? true : false;
            this.priority.canRemoveMetric = numberOfMetrics > 1 ? true : false;
        });

        this.priority.metrics = [...this.defaultMetrics, ...this.customMetrics].filter(d => d.selected).map(d => d.id);
        this.originalPriority = JSON.parse(JSON.stringify(this.priority));
        this.originalMetrics = JSON.parse(JSON.stringify([...this.defaultMetrics, ...this.customMetrics].filter(c => c.selected)));

        // Calculating Latest Metric Value
        this.riskToleranceService.riskTolerance.priorities.map(priority => priority.metrics
            .forEach(metric => this.getLatestMetricValue(priority, this.riskToleranceService.riskTolerance.metrics
                .filter(item => item.id === metric)[0])));
    }

    getLatestMetricValue(priority, metric) {
        const values = this.riskToleranceService.getSuggesteMetricByPeriod(priority.id, metric.id)
            .sort((a, b) => new Date(b.period).valueOf() - new Date(a.period).valueOf());
        let latestMetricValue = this.shortNumberPipe.transform(values.length > 0 ? values[0].currentMetric : null);
        // replacing N/A from current metric and calculating risk tolerance
        latestMetricValue = latestMetricValue === 'N/A' ? '' : latestMetricValue;
        const riskTolerance = values.length > 0 ? (values[0].riskTolerance !== null ? values[0].riskTolerance : 0) : 0;
        if (metric.isCustom) {
            this.customMetrics.find(item => item.id === metric.id).latestMetricValue = latestMetricValue;
        } else {
            this.defaultMetrics.find(item => item.id === metric.id).latestMetricValue = latestMetricValue;
        }
        this.unableToCalMetricValueAndRiskTolerance(latestMetricValue, riskTolerance, metric);
    }

    ngOnDestroy() {
        this.riskToleranceService.scrollEnabled = true;
    }

    close() {
        this.riskToleranceService.showNavigation = true;
        this.addClosed.emit(null);
    }

    cancel() {
        this.riskToleranceService.showNavigation = true;
        this.addClosed.emit(null);
    }

    ok() {
        // const data = this.form.getRawValue();
        const numberOfMetrics = this.customMetrics.filter(c => c.selected).length +
            this.defaultMetrics.filter(c => c.selected).length;
        if (numberOfMetrics < 1) {
            this.modalService.alert(this.translateService.instant('RTC.RISKTOLERANCE.VIEWANALYSIS.METRICALERT'));
            return;
        }
        if (this.form.valid) {
            const metrics = [...this.defaultMetrics, ...this.customMetrics].filter(c => c.selected);

            this.priority.metrics = [...this.defaultMetrics, ...this.customMetrics].filter(d => d.selected).map(d => d.id);
            // let needToTrigger = this.riskToleranceService.riskTolerance.metrics.map(d => d.formula).join(', ')
            //     !== [...this.defaultMetrics, ...this.customMetrics].map(d => d.formula).join(', ');
            let needToTrigger = JSON.stringify(this.originalPriority) !== JSON.stringify(this.priority)
                || JSON.stringify(this.originalMetrics) !== JSON.stringify(metrics);
            needToTrigger = needToTrigger || (this.priority.severityScore !== this.form.controls.severity.value);
            this.riskToleranceService.riskTolerance.metrics = [...this.defaultMetrics, ...this.customMetrics];
            if (needToTrigger) {
                this.savePriorityData(metrics);
                this.riskToleranceService.addOrUpdate(this.priority);

                this.riskToleranceService.calltrigger('calc_peer_analysis').subscribe(c => {
                    this.riskToleranceService.calltrigger('calc_custom_analysis').subscribe(d => {
                        this.addClosed.emit(this.priority);
                    });
                });

            } else {
                this.savePriorityData(metrics);
                this.addClosed.emit(this.priority);
            }
        } else {
            this.form.controls.priorityName.markAsTouched();
            this.form.controls.selectedPriority.markAsTouched();
        }
    }

    savePriorityData(metrics) {
        this.riskToleranceService.showNavigation = true;
        const data = this.form.getRawValue();
        if (data.selectedPriority === -1) {
            const maxPriorityId = Math.max(...this.prioritiesArray.map(d => d.id), 999);
            this.priority.id = maxPriorityId + 1;
            this.form.controls.selectedPriority.setValue(this.priority.id, { emitEvent: false });
        }
        this.priority.name = data.priorityName;
        this.priority.metrics = metrics.map(d => d.id);
        this.priority.isDeleted = false;
        this.priority.severityScore = data.severity;
    }

    metricUpdated(event, metric) {
        if (!this.priority) {
            return;
        }
        if (this.priority.canAddMetric || metric.selected) {
            metric.selected = !metric.selected;
        }
        if (!this.priority.canRemoveMetric && metric.selected === false) {
            metric.selected = !metric.selected;
            return;
        }
        const numberOfMetrics = this.customMetrics.filter(c => c.selected).length +
            this.defaultMetrics.filter(c => c.selected).length;
        this.priority.canAddMetric = numberOfMetrics < 5 ? true : false;
        this.priority.canRemoveMetric = numberOfMetrics > 1 ? true : false;
    }

    createNewMetric() {
        this.editMetrics = true;
        this.selectedMetric = null;
    }

    addMetric(metric) {
        if (metric) {
            this.riskToleranceService.metricNames.push({
                label: metric.label,
                id: metric.id
            });
        }
        if (metric && metric.isCustom) {
            this.metricCanEvaluate(metric);
            this.customMetrics.push(metric);
        }
        this.selectedMetric = null;
        this.editMetrics = false;
    }

    uniqueNameValidator(riskToleranceService: RiskToleranceService) {
        return (control: AbstractControl): { [key: string]: boolean } | null => {

            if (riskToleranceService.riskTolerance.priorities.find(c => c.name === control.value && c.id !== this.priority.id)) {
                return { 'notUniqueName': true };
            }
            return null;
        };
    }

    menuItemClicked(item, metric) {
        item.event(metric);
    }

    metricCanEvaluate(metric) {
        let metricFormula = metric.formula;
        const metricArray = this.riskToleranceService.tokenize(metricFormula, { operands: /{\d+}/ }, 'invalid');
        metricArray.forEach(c => {
            if (c.type === 'operands') {
                const dataItemId = c.token.replace('}', '').replace('{', '');
                metricFormula = metricFormula.split(c.token)
                    .join(this.getDataItemValue(dataItemId)?? 0);
            }
        });
        try {
            this.math.evaluate(metricFormula);
            metric.canEvaluate = true;
        } catch (e) {
            metric.canEvaluate = false;
        }
    }

    removeCustomMetric(metric) {
        const yesConfig = <IButtonConfig>{
            display: true,
            text: this.translateService.instant('RTC.RISKTOLERANCE.DEFINEPRIORITIES.REMOVAL.DELETEBUTTON'),
            cssClass: 'btn btn-primary'
        };

        const cancelConfig = <IButtonConfig>{
            display: true,
            text: this.translateService.instant('GLOBAL.BTN.CANCEL')
        };

        const confirmConfig = <IStandardModalConfig>{
            title: this.translateService.instant('RTC.RISKTOLERANCE.DEFINEPRIORITIESMODAL.REMOVAL.TITLE', { metricName: metric.label }),
            withHeader: true,
            message: this.translateService
                .instant('RTC.RISKTOLERANCE.DEFINEPRIORITIESMODAL.REMOVAL.MESSAGE1'),
            yesButton: yesConfig,
            noButton: cancelConfig
        };
        this.modalService.confirm(confirmConfig).then(
            (c) => {
                if (c) {
                    this.riskToleranceService.removeMetric(metric);
                    this.customMetrics.forEach((cc, i) => {
                        if (cc.id === metric.id) {
                            this.customMetrics.splice(i, 1);
                        }
                    });
                }
            }
        );
    }

    //#region Handle unable to calculate
    unableToCalMetricValueAndRiskTolerance(latestMetricValue, riskTolerance, metric) {
        const unableToCalMetric = latestMetricValue !== '' && latestMetricValue !== undefined && latestMetricValue !== null ? false : true;
        const unableToCalTolerance = riskTolerance === null ? true : false;
        if (metric.isCustom) {
            this.customMetrics.find(item => item.id === metric.id).unableToCalMetric = unableToCalMetric;
            this.customMetrics.find(item => item.id === metric.id).unableToCalTolerance = unableToCalTolerance;
        } else {
            this.defaultMetrics.find(item => item.id === metric.id).unableToCalMetric = unableToCalMetric;
            this.defaultMetrics.find(item => item.id === metric.id).unableToCalTolerance = unableToCalTolerance;
        }
    }

    private getDataItemValue(dataItemId: number): number {
        const data = this.riskToleranceService.financialStatement.projectionData
        .concat(this.riskToleranceService.financialStatement.historicalData)
            .filter(x => x.dataItemId === +dataItemId).sort((a, b) => {
                return new Date(b.periodEndDate).valueOf() - new Date(a.periodEndDate).valueOf();
            }).slice(0, 3).filter(x => x.dataItemValue);
        if (data.length > 0) {
            return data[0].dataItemValue;
        }
        return null;
    }

    //#endregion
}
