


import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-tabs-extra-menu',
    templateUrl: '../../../../../node_modules/@acl/rtc-components/src/lib/pages/financials/financialInputs/tabsExtraMenu/tabsExtraMenu.component.html',
    styleUrls: ['../../../../../node_modules/@acl/rtc-components/src/lib/pages/financials/financialInputs/tabsExtraMenu/tabsExtraMenu.component.scss']
})

export class TabsExtraMenuComponent {

    @Output() itemClicked: EventEmitter<string> = new EventEmitter<string>();
    @Input() items: any;
    public showDetails = false;


    clicked(item) {
        this.showDetails = false;
        this.itemClicked.emit(item);
    }

    openMenu() {
        this.showDetails = !this.showDetails;
    }

    clear() {
        this.showDetails = false;
    }
}
