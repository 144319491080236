
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'switch-button',
    templateUrl: '../../../../node_modules/@acl/rtc-components/src/lib/pages/riskTolerance/switchButton/switchButton.component.html',
    styleUrls: ['../../../../node_modules/@acl/rtc-components/src/lib/pages/riskTolerance/switchButton/switchButton.component.scss']
})

export class SwitchButtonComponent implements OnInit {

    @Input()
    originalState: boolean;

    @Output()
    valueChanged = new EventEmitter<boolean>();

    constructor() {
        // to fix lint
    }

    ngOnInit() {
        // to fix lint
    }

    changeState() {
        // this.originalState = !this.originalState;
        this.valueChanged.emit(this.originalState);
    }
}
