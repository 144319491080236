
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FinancialService } from 'app/services/financial.service';

@Component({
    selector: 'financial-tabs',
    templateUrl: '../../../../../../node_modules/@acl/rtc-components/src/lib/pages/financials/financialInputs/financialTabs/financialTabs.component.html',
    styleUrls: ['../../../../../../node_modules/@acl/rtc-components/src/lib/pages/financials/financialInputs/financialTabs/financialTabs.component.scss']
})

export class FinancialTabsComponent implements OnInit {


    @Output()
    pageChanged = new EventEmitter<number>();

    public translation: any = {
    };
    public translationKey = 'RTC.FINANCIALS.FINANCIALTABS';
    public data: any;
    public headers: { key: string, selected: boolean }[];
    constructor(private financialService: FinancialService) { }

    ngOnInit() {
        this.headers = this.financialService.tabHeaders;
    }

    selectTab(item) {
        if (item.selected === false) {
            this.headers.forEach(c => c.selected = false);
            item.selected = true;
            this.pageChanged.emit(item.key);
        }
    }
}

export class LinkedList {
    next: LinkedList;
    value: number;
}

