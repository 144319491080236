import { Component, OnInit, Input , ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ClientDetail } from 'app/api/generated/ClientDetail';
import { FinancialService } from 'app/services/financial.service';
import * as platformDtos from '@wtw/platform/api/dtos';
import {RunFinancialData} from 'app/api/generated/RunFinancialData';
import { ClientInfo, ScenarioProxy} from 'app/api/dtos';
import { LocalizedDatePipe, ShortNumberFormatPipe } from '@wtw/toolkit/index';
import { TranslateService } from '@ngx-translate/core';
import { ReferenceDataProxy } from 'app/api/dtos';
import { AutocompleteComponent } from '@wtw/toolkit/src/directives/autocomplete.directive';
import { ApplicationInsightsService } from '@wtw/platform/services';

@Component({
  selector: 'lib-financial-data-modal',
  templateUrl: '../../../../../node_modules/@acl/rtc-components/src/lib/pages/financials/financial-data-modal/financial-data-modal.component.html',
    styleUrls: ['../../../../../node_modules/@acl/rtc-components/src/lib/pages/financials/financial-data-modal/financial-data-modal.component.scss']
})
export class FinancialDataModalComponent implements OnInit {

  config = { ignoreBackdropClick: true, class : 'modal--without-header'};
  @ViewChild(AutocompleteComponent)  _autoComplete: AutocompleteComponent;
  enableClientSubscription: boolean;
  public searchData: any = [];
  enableButton: boolean;
  loading = false;
  selectedOption: string;
  isRadioButtonChecked = false;
  public disableUseFinancialData = false;
  @Input() modalData: ClientDetail;
  @Input() existingRun: platformDtos.RunModel;
  @Input() currentClient: platformDtos.ClientData;
  @Input() autoClear = false;

  public translationKey = 'RTC.FINANCIALS.FINANCIALDATAMODAL';

  constructor(private _bsModalRef: BsModalRef, private _financialService: FinancialService,
    private _translate: TranslateService,
    private _refApi: ReferenceDataProxy,
    private _apiScenario: ScenarioProxy,
    private _appInsightService: ApplicationInsightsService
    ) {
  }

  ngOnInit()  {
    //
  }

  search: any = (v: string) => { // to capture the "this"
    return this._refApi.clientSearchFinancialData(v);

  }

   activate(data: ClientInfo) {
    this.searchData = data;
   }

  getShortNumberValue(revenue: number): string|undefined {
    if (revenue) {
        const shortNumPipe = new ShortNumberFormatPipe();
        return '$' + shortNumPipe.transform(revenue, 1);

    } else {
        return '-';
    }
  }

  getFormattedDate(savedDate: string): string|undefined {
    if (savedDate) {
        const datePipe = new LocalizedDatePipe(this._translate);
        return datePipe.transform(savedDate, 'dd MMM yyyy');

    } else {
        return '-';
    }
  }


onRowClick(option: string) {
  this.selectedOption = option;
  this.isRadioButtonChecked = true;
}
statuschanged(status: boolean) {
  this.loading = status;
  this.isRadioButtonChecked = false;
}

public updateRunFinancialData(): void {
  this.hide();

  const financialData: RunFinancialData = {
    clientId: this.searchData.id,
    clientName: this.searchData.name,
    exchangeCode: this.searchData.exchangeCode,
    revenue: this.searchData.revenue,
    numberofEmployees: this.searchData.numberofEmployees,
    originalClientId: this.currentClient.clientId,
    originalClientName: this.currentClient.displayName,
    runId: this.existingRun.runId
  };

    this._apiScenario.updateRunFinancialData(financialData)
        .uiSignal('Loading')
        .subscribe(d => {
          this._appInsightService.send({
            eventCategory: 'Client - Runs',
            eventAction: 'FinancialDataUpdatedForRun',
            runId: this.existingRun.runId,
            addtionalFields: { updateClientId: d.data.generalClientInfo.clientId }
          });
          this.existingRun.data = d.data;
          this._financialService.reloadRun(this.existingRun);
          this._financialService.HideCompanyTypeView();
        });
}

public  hide() {
  this._bsModalRef.hide();
}

}


